interface WinkworthToAdd {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
}

export const WINKWORTH_TO_ADD: WinkworthToAdd[] = [
    {
        email: 'ltaylor@winkworth.co.uk',
        phone: '+44(0) 1778 392807',
        firstName: 'Louise',
        lastName: 'Taylor',
    },
    {
        email: 'jmoore@winkworth.co.uk',
        phone: '+44 (0) 1603 881010',
        firstName: 'Jamie',
        lastName: 'Moore',
    },
    {
        email: 'rjones@winkworth.co.uk',
        phone: '+44 (0) 1778 347098',
        firstName: 'Ryan',
        lastName: 'Jones',
    },
    {
        firstName: 'Paul',
        lastName: 'Benge',
        phone: '+44 (0) 1702 470625',
        email: 'pbenge@winkworth.co.uk',
    },
    {
        firstName: 'Victoria',
        lastName: 'Needle',
        phone: '+44 (0) 1476 578 888',
        email: 'vneedle@winkworth.co.uk',
    },
    {
        firstName: 'Mark',
        lastName: 'Wilkins',
        phone: '+44 (0) 1787 326740',
        email: 'mwilkins@winkworth.co.uk',
    },
    {
        firstName: 'Sarah',
        lastName: 'Waldeck',
        phone: '+44 (0) 1529 303 377',
        email: 'swaldeck@winkworth.co.uk',
    },
    {
        firstName: 'Winkworth',
        lastName: 'Newark',
        phone: '+44 (0) 1636 611666',
        email: 'newark@winkworth.co.uk',
    },
    {
        firstName: 'Ian',
        lastName: 'Simons',
        phone: '+44 (0) 1604 204455',
        email: 'isimons@winkworth.co.uk',
    },
    {
        firstName: 'Anthony',
        lastName: 'Blackstone',
        phone: '+44 (0) 1276 402229',
        email: 'ablackstone@winkworth.co.uk',
    },
    {
        firstName: 'Justin',
        lastName: 'Richardson',
        phone: '+44 (0) 1494 677978',
        email: 'jrichardson@winkworth.co.uk',
    },
    {
        firstName: 'Donna',
        lastName: 'Pearson',
        phone: '+44 (0) 1227 456645',
        email: 'dpearson@winkworth.co.uk',
    },
    {
        firstName: 'Simon',
        lastName: 'Barnes',
        phone: '+44 (0) 1425 270 055',
        email: 'sbarnes@winkworth.co.uk',
    },
    {
        firstName: 'Andrew',
        lastName: 'Beecheno',
        phone: '+44 (0) 1730 267274',
        email: 'abeecheno@winkworth.co.uk',
    },
    {
        firstName: 'Sebastian',
        lastName: 'Clarke',
        phone: '+44 (0) 1794 511911',
        email: 'sclarke@winkworth.co.uk',
    },
    {
        firstName: 'Ben',
        lastName: 'Jesty',
        phone: '+44 (0) 1202 767633',
        email: 'bjesty@winkworth.co.uk',
    },
    {
        firstName: 'Neil',
        lastName: 'Barnes',
        phone: '+44 (0) 20 8335 5555',
        email: 'nbarnes@winkworth.co.uk',
    },
    {
        firstName: 'Andrew',
        lastName: 'Booker',
        phone: '+44 (0) 1737 362 362',
        email: 'abooker@winkworth.co.uk',
    },
    {
        firstName: 'Mark',
        lastName: 'Atterano',
        phone: '+44 (0) 20 8650 1000',
        email: 'matterano@winkworth.co.uk',
    },
    {
        firstName: 'Henry',
        lastName: 'Griffin',
        phone: '+44 (0) 1252 733042',
        email: 'hgriffin@winkworth.co.uk',
    },
    {
        firstName: 'Richard',
        lastName: 'Everitt',
        phone: '+44 (0) 1273 471471',
        email: 'reveritt@winkworth.co.uk',
    },
    {
        firstName: 'Gareth',
        lastName: 'Bowden',
        phone: '+44 (0) 1425 274444',
        email: 'gbowden@winkworth.co.uk',
    },
    {
        firstName: 'Neil',
        lastName: 'Roff',
        phone: '+44 (0) 1202 733000',
        email: 'nroff@winkworth.co.uk',
    },
    {
        firstName: 'Matthew',
        lastName: 'Hallett',
        phone: '+44 (0) 1722 443000',
        email: 'mhallett@winkworth.co.uk',
    },
    {
        firstName: 'Tom',
        lastName: 'Lepsky',
        phone: '+44 (0) 1932 854400',
        email: 'tlepsky@winkworth.co.uk',
    },
    {
        firstName: 'Stephen',
        lastName: 'Magee',
        phone: '+44 (0) 1903 216219',
        email: 'smagee@winkworth.co.uk',
    },
    {
        firstName: 'Michael',
        lastName: "O'Shea",
        phone: '+44 (0) 1256 811730',
        email: 'moshea@winkworth.co.uk',
    },
    {
        firstName: 'Toby',
        lastName: 'Powell',
        phone: '+44 (0) 1273 772 175',
        email: 'tpowell@winkworth.co.uk',
    },
    {
        firstName: 'Carly',
        lastName: 'Holbrook',
        phone: '+44 (0) 20 8285 5099',
        email: 'cholbrook@winkworth.co.uk',
    },
    {
        firstName: 'Rosella',
        lastName: 'Short',
        phone: '+44 (0) 1202 434365',
        email: 'rshort@winkworth.co.uk',
    },
    {
        firstName: 'Sandra',
        lastName: 'Hutchinson',
        phone: '+44 (0) 1635 552552',
        email: 'shutchinson@winkworth.co.uk',
    },
    {
        firstName: 'Michael',
        lastName: 'Foldvari',
        phone: '+44 (0) 118 4022300',
        email: 'mfoldvari@winkworth.co.uk',
    },
    {
        firstName: 'James',
        lastName: 'Hadley-Clift',
        phone: '+44 (0) 1202 434365',
        email: 'jhclift@winkworth.co.uk',
    },
    {
        firstName: 'Natasha',
        lastName: 'Firman',
        phone: '+44 (0) 1892 519600',
        email: 'nfirman@winkworth.co.uk',
    },
    {
        firstName: 'Daniel',
        lastName: 'Merritt',
        phone: '+44 (0) 1962 866777',
        email: 'dmerritt@winkworth.co.uk',
    },
    {
        firstName: 'Matthew',
        lastName: 'Leonard',
        phone: '+44 (0) 1225 829000',
        email: 'mleonard@winkworth.co.uk',
    },
    {
        firstName: 'Richard',
        lastName: 'Blake',
        phone: '+44 (0) 1803 832288',
        email: 'rblake@winkworth.co.uk',
    },
    {
        firstName: 'Chris',
        lastName: 'Collinge',
        phone: '+44 (0) 1590 642641',
        email: 'ccollinge@winkworth.co.uk',
    },
    {
        firstName: 'Trevor',
        lastName: 'Branton',
        phone: '+44 (0) 1803 444595',
        email: 'tbranton@winkworth.co.uk',
    },
    {
        firstName: 'Tom',
        lastName: 'Mayfield',
        phone: '+44 (0) 1242 386586',
        email: 'tmayfield@winkworth.co.uk',
    },
    {
        firstName: 'Catherine',
        lastName: 'Smith',
        phone: '+44 (0) 1363 773 757',
        email: 'csmith@winkworth.co.uk',
    },
    {
        firstName: 'Emily',
        lastName: 'Hall',
        phone: '+44 (0) 1392 271177',
        email: 'ehall@winkworth.co.uk',
    },
    {
        firstName: 'Kim',
        lastName: 'Watts',
        phone: '+44 (0) 1884 675675',
        email: 'kwatts@winkworth.co.uk',
    },
    {
        firstName: 'Matthew',
        lastName: 'Higson',
        phone: '+44 (0) 20 7691 4271',
        email: 'mhigson@winkworth.co.uk',
    },
    {
        firstName: 'Winkworth',
        lastName: 'Commercial',
        phone: '',
        email: 'commercial@winkworth.co.uk',
    },
    {
        firstName: 'Ian',
        lastName: 'Fraser',
        phone: '',
        email: 'ifraser@winkworth.co.uk',
    },
    {
        firstName: 'Hamish',
        lastName: 'Allan',
        phone: '',
        email: 'hallan@winkworth.co.uk',
    },
    {
        firstName: 'Katherine',
        lastName: 'Long',
        phone: '',
        email: 'klong@winkworth.co.uk',
    },
    {
        firstName: 'Joshua',
        lastName: 'Grinling',
        phone: '',
        email: 'jgrinling@winkworth.co.uk',
    },
    {
        firstName: 'Will',
        lastName: 'Yule',
        phone: '',
        email: 'wyule@winkworth.co.uk',
    },
    {
        firstName: 'Neil',
        lastName: 'Sloam',
        phone: '',
        email: 'nsloam@winkworth.co.uk',
    },
    {
        firstName: 'Stuart',
        lastName: 'Rothstein',
        phone: '',
        email: 'srothstein@winkworth.co.uk',
    },
    {
        firstName: 'Jason',
        lastName: 'Borrows',
        phone: '',
        email: 'jborrows@winkworth.co.uk',
    },
    {
        firstName: 'Winkworth',
        lastName: 'New Homes',
        phone: '',
        email: 'newhomes@winkworth.co.uk',
    },
    {
        firstName: 'Kiren',
        lastName: 'Awan',
        phone: '',
        email: 'kawan@winkworth.co.uk',
    },
    {
        firstName: 'Lucinda',
        lastName: 'Richardson',
        phone: '',
        email: 'lrichardson@winkworth.co.uk',
    },
    {
        firstName: 'Giles',
        lastName: 'Hoskins',
        phone: '',
        email: 'ghoskins@winkworth.co.uk',
    },
    {
        firstName: 'Charlie',
        lastName: 'Rosling',
        phone: '',
        email: 'crosling@winkworth.co.uk',
    },
    {
        firstName: 'Nick',
        lastName: 'Goble',
        phone: '',
        email: 'ngoble@winkworth.co.uk',
    },
    {
        firstName: 'Chris',
        lastName: 'Roupell',
        phone: '',
        email: 'croupell@winkworth.co.uk',
    },
    {
        firstName: 'Simon',
        lastName: 'Kyriacou',
        phone: '',
        email: 'skyriacou@winkworth.co.uk',
    },
    {
        firstName: 'Adrian',
        lastName: 'Philpott',
        phone: '',
        email: 'arhilpott@winkworth.co.uk',
    },
    {
        firstName: 'Rod',
        lastName: 'Heffernan',
        phone: '',
        email: 'rheffernan@winkworth.co.uk',
    },
    {
        firstName: 'Jeff',
        lastName: 'Cohen',
        phone: '',
        email: 'jcohen@winkworth.co.uk',
    },
    {
        firstName: 'James',
        lastName: 'Ballard',
        phone: '',
        email: 'jballard@winkworth.co.uk',
    },
    {
        firstName: 'Graham',
        lastName: 'Gold',
        phone: '',
        email: 'ggold@winkworth.co.uk',
    },
    {
        firstName: 'Howard',
        lastName: 'Greenfield',
        phone: '',
        email: 'ggreenfield@winkworth.co.uk',
    },
    {
        firstName: 'Elan',
        lastName: 'Silver',
        phone: '',
        email: 'esilver@winkworth.co.uk',
    },
    {
        firstName: 'Josh',
        lastName: 'Kravitz',
        phone: '',
        email: 'jkravtiz@winkworth.co.uk',
    },
    {
        firstName: 'Michael',
        lastName: 'Persaud',
        phone: '',
        email: 'mpersaud@winkworth.co.uk',
    },
    {
        firstName: 'Kamal',
        lastName: 'Makwana',
        phone: '',
        email: 'kmakwana@winkworth.co.uk',
    },
    {
        firstName: 'James',
        lastName: 'Wilkinson',
        phone: '',
        email: 'jwilkinson@winkworth.co.uk',
    },
    {
        firstName: 'Stewart',
        lastName: 'Boyd',
        phone: '',
        email: 'sboyd@winkworth.co.uk',
    },
    {
        firstName: 'Clive',
        lastName: 'Nunes',
        phone: '',
        email: 'cnunes@winkworth.co.uk',
    },
    {
        firstName: 'Ajay',
        lastName: 'Bathija',
        phone: '',
        email: 'abathija@winkworth.co.uk',
    },
    {
        firstName: 'Nick',
        lastName: 'Charalambous',
        phone: '',
        email: 'ncharalambous@winkworth.co.uk',
    },
    {
        firstName: 'Mark',
        lastName: 'Epps',
        phone: '',
        email: 'mepps@winkworth.co.uk',
    },
    {
        firstName: 'Helen',
        lastName: 'Epps',
        phone: '',
        email: 'hepps@winkworth.co.uk',
    },
    {
        firstName: 'Jake',
        lastName: 'Woolford',
        phone: '',
        email: 'jwoolford@winkworth.co.uk',
    },
    {
        firstName: 'Alex',
        lastName: 'Vent',
        phone: '',
        email: 'avent@winkworth.co.uk',
    },
    {
        firstName: 'Sean',
        lastName: 'Doyle',
        phone: '',
        email: 'sdoyle@winkworth.co.uk',
    },
    {
        firstName: 'Marc',
        lastName: 'Wiehe',
        phone: '',
        email: 'mwiehe@winkworth.co.uk',
    },
    {
        firstName: 'Nigel',
        lastName: 'Field',
        phone: '',
        email: 'nfield@winkworth.co.uk',
    },
    {
        firstName: 'Tom',
        lastName: 'Floyd',
        phone: '',
        email: 'tfloyd@winkworth.co.uk',
    },
    {
        firstName: 'Alex',
        lastName: 'Eaton',
        phone: '',
        email: 'aeaton@winkworth.co.uk',
    },
    {
        firstName: 'Alex',
        lastName: 'Patton',
        phone: '',
        email: 'apatton@winkworth.co.uk',
    },
    {
        firstName: 'Sarah',
        lastName: 'Spencer',
        phone: '',
        email: 'sspencer@winkworth.co.uk',
    },
    {
        firstName: 'Donovan',
        lastName: 'Kelly',
        phone: '',
        email: 'dkelly@winkworth.co.uk',
    },
    {
        firstName: 'Faith',
        lastName: 'Cook',
        phone: '',
        email: 'fcook@winkworth.co.uk',
    },
    {
        firstName: 'Russell',
        lastName: 'White',
        phone: '',
        email: 'rwhite@winkworth.co.uk',
    },
    {
        firstName: 'Douglas',
        lastName: 'Moss',
        phone: '',
        email: 'dmoss@winkworth.co.uk',
    },
    {
        firstName: 'Benjamin',
        lastName: 'Wear',
        phone: '',
        email: 'bwear@winkworth.co.uk',
    },
    {
        firstName: 'Charles',
        lastName: 'Mitchell',
        phone: '',
        email: 'cmitchell@winkworth.co.uk',
    },
    {
        firstName: 'Philip',
        lastName: 'Bartlett',
        phone: '',
        email: 'pbartless@winkworth.co.uk',
    },
    {
        firstName: 'Mana',
        lastName: 'Legha',
        phone: '',
        email: 'mlegha@winkworth.co.uk',
    },
    {
        firstName: 'Gabriela',
        lastName: 'Legha',
        phone: '',
        email: 'glegha@winkworth.co.uk',
    },
    {
        firstName: 'Elinor',
        lastName: 'Sheehan',
        phone: '',
        email: 'esheehan@winkworth.co.uk',
    },
    {
        firstName: 'Fiona',
        lastName: 'Lee',
        phone: '',
        email: 'flee@winkworth.co.uk',
    },
    {
        firstName: 'Ben',
        lastName: 'Hunt',
        phone: '',
        email: 'bhunt@winkworth.co.uk',
    },
    {
        firstName: 'Carl',
        lastName: 'Burgess',
        phone: '',
        email: 'cburgess@winkworth.co.uk',
    },
    {
        firstName: 'Dominic',
        lastName: 'Cocklin',
        phone: '',
        email: 'dcocklin@winkworth.co.uk',
    },
];

export const WINKWORTHS: string[] = [
    //'talbotmatt358@gmail.com',
    'business@test.com',
    'ljacobs@winkworths.co.uk',
    'ljacobs@winkworth.co.uk',
    'winkworthexample@mail.com',
    ...WINKWORTH_TO_ADD.map((account) => account.email),
    'clerkenwell@winkworth.co.uk',
    'commercial@winkworth.co.uk',
    'islington@winkworth.co.uk',
    'kensington@winkworth.co.uk',
    'knightsbridge@winkworth.co.uk',
    'maidavale@winkworth.co.uk',
    'marylebone@winkworth.co.uk',
    'newhomes@winkworth.co.uk',
    'northkensington@winkworth.co.uk',
    'nottinghill@winkworth.co.uk',
    'paddington@winkworth.co.uk',
    'pimlico@winkworth.co.uk',
    'southkensington@winkworth.co.uk',
    //'stjohnswood@winkworth.co.uk',
    'westend@winkworth.co.uk',
    'barnet@winkworth.co.uk',
    'borehamwood@winkworth.co.uk',
    //'crouchend@winkworth.co.uk',
    'finchley@winkworth.co.uk',
    //'goldersgreen@winkworth.co.uk',
    'harringay@winkworth.co.uk',
    //'harrow@winkworth.co.uk',
    'hendon@winkworth.co.uk',
    'highbury@winkworth.co.uk',
    'kensalrise@winkworth.co.uk',
    'kentishtown@winkworth.co.uk',
    'kingsbury@winkworth.co.uk',
    'maidavale@winkworth.co.uk',
    //'palmersgreen@winkworth.co.uk',
    'blackheath@winkworth.co.uk',
    'chislehurst@winkworth.co.uk',
    'crystalpalace@winkworth.co.uk',
    //'dulwich@winkworth.co.uk',
    'foresthill@winkworth.co.uk',
    'greenwich@winkworth.co.uk',
    //'hernehill@winkworth.co.uk',
    'kennington@winkworth.co.uk',
    'newcross@winkworth.co.uk',
    'surreyquays@winkworth.co.uk',
    'westnorwood@winkworth.co.uk',
    'barnes@winkworth.co.uk',
    'battersea@winkworth.co.uk',
    'cheam@winkworth.co.uk',
    'clapham@winkworth.co.uk',
    'fulham@winkworth.co.uk',
    'putney@winkworth.co.uk',
    'southfields@winkworth.co.uk',
    'streatham@winkworth.co.uk',
    'surbiton@winkworth.co.uk',
    //'tooting@winkworth.co.uk',
    'wimbledon@winkworth.co.uk',
    'worcesterpark@winkworth.co.uk',
    'brentford@winkworth.co.uk',
    //'chiswick@winkworth.co.uk',
    'ealing@winkworth.co.uk',
    'hammersmith@winkworth.co.uk',
    'shepherdsbush@winkworth.co.uk',
    'bow@winkworth.co.uk',
    'hackney@winkworth.co.uk',
    'shoreditch@winkworth.co.uk',
    'kherbing@winkworth.co.uk',
    'petersfield@winkworth.co.uk',
    'bath@winkworth.co.uk',
    'canterbury@winkworth.co.uk'
];

export const RURAL_VIEW: string[] = [
    //'talbotmatt358@gmail.com',
    'business2@test.com',
    'jane_doe@mail.com',
    'charlie@ruralview.co.uk',
];

export const FOXTONS: string[] = [
    'example@foxtons.com'
];

export const KNIGHT_FRANK: string[] = [
    'example@knightfrank.com'
];

export const SAVILLS: string[] = [
    'example@savills.com'
];

export const TEST: string[] = [
    'businesstest@test.com'
];

export const CAVENDISH: string[] = [
    'cavnuctest.@test.com',
    'jane.king@cavendishnuclear.com'
]

export const EDEN_CERTIFIED: string[] = [
    'winkworthexample@mail.com',
    'jeff@ochre.net'
]

/**
 * EDIT THESE WHEN A NEW ESTATE AGENT IS ADDED FOR LEADERBOARD TO WORK AND ADD BUSINESS NAME
 */

export const ACCOUNTS: string[][] = [
    WINKWORTHS,
    RURAL_VIEW,
    TEST,
];

export const BUSINESS_ACCOUNTS_LIST = [
    ...WINKWORTHS,
    ...RURAL_VIEW,
    ...TEST,
];

export const ESTATE_AGENTS = [
    ...WINKWORTHS,
    ...RURAL_VIEW,
    'jon_smith@example.com',
    ...FOXTONS,
    ...KNIGHT_FRANK,
    ...SAVILLS,
];

export const getBusinessLogo = (email: string): string => {
    if(RURAL_VIEW.includes(email)) {
        return 'rural-view-logo.png';
    } else if(WINKWORTHS.includes(email)) {
        return 'winkworth-logo.png';
    } else if (TEST.includes(email)) {
        return 'test-logo.jpg'
    } else {
        return '';
    }
};

export const getBusinessName = (email: string): string => {
    if (RURAL_VIEW.includes(email)) {
        return 'Rural View';
    } else if (WINKWORTHS.includes(email)) {
        return 'Winkworth';
    } else if (TEST.includes(email)) {
        return 'Test Inc.';
    } else if(FOXTONS.includes(email)) {
        return 'Foxtons';
    } else if(SAVILLS.includes(email)) {
        return 'Savills';
    } else if(KNIGHT_FRANK.includes(email)) {
        return 'Knight Frank';
    } else if(email === 'jeff@ochre.net') {
        return 'Ochre';
    } else {
        return '';
    } 
};
