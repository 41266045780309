import { Component, Input, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

export interface FaqInput {
  dropdown: string;
  text: string;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @Input() faqInput?: FaqInput[];

  private seoService: SeoService;

  public readonly faqTexts: string[] = [
    "For collective action to work, we have to be open with one another. We're not in this for profit, we're in this to make an impact. \n\nThat's why we pledge that 80% of your money goes directly towards supporting climate projects, including associated transaction fees carried out on your behalf. \n\nWe'll use the rest to keep our lights on, and spread the word about our community. \n\nWhat's more, we make every effort to ensure your money goes as far as possible, and has the greatest collective impact possible.",
    "Eden Greenspace began in March 2020 when the first UK lockdown was imposed. We felt that while Covid was a blight on the happiness of the planet, it also represented a new start for us all, and founding Eden Greenspace was our way of building back greener.\n\nEden Greenspace is our vehicle for effecting collaborative and meaningful change to stop the environmental destruction of our planet, and encouraging the general public to take climate action into their own hands.\n\nWe were tired of seeing too many gestures, too many words, and not enough action. That's why we decided to create a service which allows ordinary people to take action into their own hands, by supporting the environmental action projects which which mean the most to them.\n\nThrough our projects, we want to create a movement to increase public action against environmental degradation and fast-track the route out of the global climate crisis.",
    "We all contribute to the climate crisis with our everyday activities, even if we don’t realise it.\n\nFlying, driving and meat-eating are big factors, but even taking public transport, a vegetarian diet and being energy efficient does not mitigate the amount of carbon we all emit.\n\nWhether it’s our use of plastic packaging, or the carbon emitted through the transportation of goods and services, we are all complicit in the emergence of our global climate crisis, and we must come together now to take action in halting environmental destruction and climatic warming.\n\nBy supporting our projects, you can take real action across the world and help fight against climate change.",
  ];

  constructor(seoService: SeoService) { 
    this.seoService = seoService;
  }

  ngOnInit(): void {
    if(!this.faqInput) {
      this.seoService.setSeo('FAQs', 'We pledge that 80% of your money goes directly towards our environmental projects.');
    }
  }

}
