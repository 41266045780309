<div [class]="'content ' + color">
    <div class="mob partner-text">Major Corporate Partners:</div>
    <div class="partner-container">
        <div class="desk partner-text">Major Corporate Partners:</div>
        <app-carousel class="mob" [contentMode]="true" [itemsPerRow]="2">
            <img *ngFor="let logo of partnerLogos" class="gallery-image" [src]="'../../../assets/' + logo" />
        </app-carousel>
        <app-carousel class="desk car" [images]="partnerLogos" [desktop]="true"></app-carousel>
    </div>
    <br />
</div>

