import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';;
import { combineLatest } from 'rxjs';
import { getAccountStepsPercentage } from 'server/account-setup';
import { CAVENDISH, getBusinessLogo, getBusinessName } from 'server/accounts';
import { Project, projects } from 'server/projects';
import { AccountStep, Level } from 'server/types';
import { AuthService, PlanSummary } from 'src/app/services/auth.service';
import { SeoService } from 'src/app/services/seo.service';
import { BusinessPurchase } from '../admin/admin.component';
import { Plan, plans, SelectedProject } from '../choose-plan/choose-plan.component';
import { CartItem, PROJECT_GROUPS } from '../gifting/gifting.component';
import { TOTAL_IMPACT_TEXTS } from '../mission/mission.component';
import { createImpactText } from '../plan-summary/plan-summary.component';
import { getProjectUrl } from '../projects/projects.component';
declare var Stripe: any;

interface ImpactData {
  id: number;
  value: number;
}

interface ImpactDetails {
  id: number;
  generateText: (tokens: number, id: string) => string;
}

export interface ImpactText {
  id: number;
  text: string;
}

type AccountContent = 'dashboard' | 'orders' | 'media' | 'how-it-works';

const IMPACT_DETAILS: ImpactDetails[] = [
  {
    id: 1,
    generateText: (tokens: number, id: string) => {
      const timestamp = id.toString().substring(0,8);
      const seconds = new Date(parseInt( timestamp, 16 ) * 1000).getSeconds();
      
      let text = '<b>Tree Species:</b> Rhizophora mangle \n \n';
      text = text + '<b>Environmental Impact:</b> Planted around the southern tip of Mozambique, in close proximity to Maputo, these trees provide stability against erosion and improve ocean and coral reef health. \n \n';
      text = text + '<b>Annual CO2 absorbed per tree:</b> 12.3 kg \n \n';
      text = text + '<b>Lifetime CO2 absorbed per tree (25 years):</b> 308.3 kg \n \n';
      
      const locationNum = Math.floor(seconds / 15);

      const locations = [
        'Tsolombane Reserve',
        'Bela Vista',
        'Madjuva',
        'Mugazine',
      ];
      
      text = text + '<b>Location:</b> ' + locations[locationNum] + ', Maputo Bay';

      return text;
    }
  },
  {
    id: 2,
    generateText: (tokens: number, id: string) => {
      const timestamp = id.toString().substring(0,8);
      const seconds = new Date(parseInt( timestamp, 16 ) * 1000).getSeconds();
      const minutes = new Date(parseInt( timestamp, 16 ) * 1000).getMinutes();

      const locationNum = Math.floor(seconds / 3);
      
      const locations = [
        'Manampatra', 
        'Bevatry', 
        'Kamendriky', 
        'Anosibe', 
        'Ambatolafia', 
        'Andanivato',
        'Marotaolo', 
        'Masokoamena',
        'Katsepy', 
        'Ambilo', 
        'Boanamary', 
        'Mahajanga', 
        'Berivotra', 
        'Antsiantia', 
        'Ambondrombe', 
        'Ankilahila', 
        'Androhibe', 
        'Andasibe', 
        'Antambao', 
        'Ankarafantsika',
      ];

      const speciesNum = Math.floor(minutes / 20);
      
      const location = locations[locationNum];

      const speciesList = [
        'Adansonia grandidieri', 
        'Adansonia madagascariensis', 
        'Adansonia suarezensis',
      ];

      const species = location === 'Ankarafantsika' ? speciesList[speciesNum] : 'Rhizophora mangle'

      let text = '<b>Tree Species:</b> ' + species + '\n \n';
      
      const envImpact = location === 'Ankarafantsika' 
        ? 'These tropical dry deciduous forests are home to eight species of endangered lemurs. They provide stability to the land and protect against erosion and flooding, and restore and expand vital animal habitats.'
        : 'These tree provide stability against erosion and improve ocean and coral reef health.'
      
      text = text + envImpact + '\n \n';
      text = text + '<b>Annual CO2 absorbed per tree:</b> 6 kg \n \n';
      text = text + '<b>Lifetime CO2 absorbed per tree (25 years):</b> 150 kg \n \n';

      text = text + '<b>Location:</b> ' + locations[locationNum];

      return text;
    }
  },
  {
    id: 3,
    generateText: (tokens: number, id: string) => {
      const timestamp = id.toString().substring(0,8);
      const seconds = new Date(parseInt( timestamp, 16 ) * 1000).getSeconds();
  
      const locationNum = Math.floor(seconds / 15);
      
      const locations = [
        'Milihoi',
        'Kichwa cha Nyoka',
        'The Great Rift Valley: Kijabe Forest, Big Fig',
        'The Great Rift Valley: Kijabe Forest, Monkey Corner',
      ];
      
      const location = locations[locationNum];

      const species = location === 'Milihoi' || 'Kichwa cha Nyoka' 
        ? 'Rhizophora mangle'
        : 'Afromontane species';

      let text = '<b>Tree Species:</b> ' + species + '\n \n';
      
      const envImpact = location === 'Milihoi' || 'Kichwa cha Nyoka' 
        ? 'These trees provide stability against erosion and improve ocean and coral reef health.'
        : 'These trees provide habitats for wildlife, sustainable economic opportunities for local families, a reliable water supply, and landslide protection.';
      
      text = text + envImpact + '\n \n';
      text = text + '<b>Annual CO2 absorbed per tree:</b> 12.3 kg \n \n';
      text = text + '<b>Lifetime CO2 absorbed per tree (25 years):</b> 308.3 kg \n \n';

      text = text + '<b>Location:</b> ' + locations[locationNum];

      return text;
    }
  },
  {
    id: 4,
    generateText: (tokens: number, id: string) => {
      const timestamp = id.toString().substring(0,8);
      const seconds = new Date(parseInt( timestamp, 16 ) * 1000).getSeconds();
      const minutes = new Date(parseInt( timestamp, 16 ) * 1000).getMinutes();
      
      const beeSpeciesDivisor = 60 / 7;
      const beeSpeciesNum = Math.floor(seconds / beeSpeciesDivisor);

      const beeSpecies = [
        'Tree bumblebee (Bombus hypnorum)', 
        'Red-tailed bumblebee', 
        'White-tailed bumblebee', 
        'Common carder bee', 
        'Red mason bee', 
        'Tawny mining bee', 
        'Ashy mining bee',
      ];

      let text = '<b>Bee species:</b> ' + beeSpecies[beeSpeciesNum] + ' \n \n';

      const flowerSpeciesNum = Math.floor(minutes / 15);

      const flowerSpecies = [
        "Crested Dog’s-tail, Greater Knapweed, Red Clover, Buttercup Meadow Crane's-bill.",
        "Cat's-ear Diasies, Sweet Vernal Grass, Bird’s-foot Trefoil and Lady’s Bedstraw.",
        "Oxeye Diasy, Yorkshire Fog, Bush Vetch and Buttercups.",
        "Wildflowers such as Sainfoin, Buttercups, Cowslips and Wild Carrot.",
      ];

      text = text + '<b>Wildflower species:</b> ' + flowerSpecies[flowerSpeciesNum] + '\n \n';
      text = text + '<b>Location:</b> Swanscombe Marsh, Kent';

      return text;
    }
  },
  {
    id: 5,
    generateText: (tokens: number, id: string) => {
      const timestamp = id.toString().substring(0,8);
      const seconds = new Date(parseInt( timestamp, 16 ) * 1000).getSeconds();
      const minutes = new Date(parseInt( timestamp, 16 ) * 1000).getMinutes();
      const milliseconds = new Date(parseInt( timestamp, 16 ) * 1000).getMilliseconds();

      const type1Num = Math.floor(seconds / 12);

      let types = [
        'plastic bottles', 
        'old fish nets', 
        'plastic bags', 
        'old fishing line', 
        'plastic packaging',
      ];

      const type1 = types[type1Num];

      const type2Num = Math.floor(minutes / 15);

      types = types.filter((type) => type !== type1);

      const type2 = types[type2Num];

      let text = '<b>Type of plastic removed:</b> ' + type1 + ' and ' + type2 + ' \n \n' ;

      const locationNum = Math.floor(milliseconds / 250);

      const locations = [
        'Isles of Scilly',
        'Cornish coastline', 
        'Helford River', 
        'Brittany',
      ];

      text = text + '<b>Location:</b> ' + locations[locationNum] + ' \n \n';
      text = 
        text + 
        '<b>Weight:</b> ' + 
        tokens + 
        "kg - that's equivalent to " +
        tokens * 100 + 
        ' plastic bottles, ' + 
        tokens * 1000 +
        ' plastic bags, or ' +
        tokens * 2500 +
        ' plastic straws \n \n'

      return text;
    }
  },
  {
    id: 6,
    generateText: (tokens: number, id: string) => {
      const timestamp = id.toString().substring(0,8);
      const seconds = new Date(parseInt( timestamp, 16 ) * 1000).getSeconds();
      
      let text = '<b>CO2 Removed:</b> ' + Math.floor(tokens * (1100 * 0.08)) + 'kg \n \n';

      const locationNum = Math.floor(seconds / 15);

      const locations = [
        'Zambia',
        'Malawi',
        'Tanzania',
        'Kenya',
      ];

      text = text + '<b>Location:</b> ' + locations[locationNum] + ' \n \n';
      text = text + "<b>Money you'll save a family in energy costs:</b> £" + tokens * 12.72 + '\n \n';
      text = text + '<b>No. of years of clean lighting:</b> 10 years. \n \n';
      text = text + '<b>Extra study time you provide for children:</b> ' + Math.floor(tokens * (1000 * 0.08)) + ' hours \n \n';
      text = 
        text + 
        '<b>Families you help to live a healthier life, free from toxic kerosene lighting:</b> ' + 
        (tokens * 0.08) + 
      ((tokens * 0.08) === 1 ? ' family' : ' families') +
        ' \n \n';

      return text
    }
  },
  {
    id: 7,
    generateText: (tokens: number, id: string) => {
      const timestamp = id.toString().substring(0, 8);
      const seconds = new Date(parseInt(timestamp, 16) * 1000).getSeconds();

      const locationNum = Math.floor(seconds / 30);

      const locations = [
        'Nakasonola, Nakaseke',
        'Mukono, Uganda',
      ];

      let text = '<b>Locations:</b> ' + locations[locationNum] +  '\n \n';
      text = text + '<b>Lifespan of clean cookstoves:</b> 5-10 years \n \n';
      text = text + '<b>Average reduction in woodfuel</b> (compared to open fires): 66% reduction \n \n';
      text = text + '<b>Annual CO2 emissions avoided:</b> 2.9 tonnes \n \n';
      text = text + '<b>Lifetime CO2 emissions avoided:</b> 5 tonnes (conservative estimate based on 5 year lifespan. Maintained cookstoves can last for 10+ years). \n \n';

      return text;
    }
  },
  {
    id: 8,
    generateText: (tokens: number, id: string) => {
      let text = '<b>Location:</b> Cozumel Reefs National Park, Cozumel Island. \n \n';
      text = text + '<b>Area covered by project:</b>: 2,826 square kilometers. \n \n';
      text = text + '<b>Length of project:</b> at least 10 years implementation and monitoring. \n \n';
      text = text + '<b>No. of coral species restored:</b> 60 species. \n \n';

      return text;
    }
  },
  {
    id: 9,
    generateText: (tokens: number, id: string) => {
      let text = '<b>Location:</b> Wahikuli Watershed, Maui Island and Molokai Island. \n \n';
      text = text + '<b>Area covered by project:</b>: 30,000 acres of ocean and coastline. \n \n';
      text = text + '<b>Length of project:</b> at least 10 years implementation and monitoring. \n \n';
      text = text + '<b>No. of coral species restored:</b> 50 species. \n \n';

      return text;
    }
  },
  {
    id: 11,
    generateText: (tokens: number, id: string) => {
      let text = '<b>Location and size:</b>  this project will ultimately span 260 ha across the Scottish lowlands. \n \n';
      text = text + '<b>Carbon Sequestration</b>  = 0.1kg CO2 per m2 restored. \n \n';
      text = text + '<b>Timeframe:</b>  4+ years action and monitoring. \n \n';

      return text;
    }
  },
];

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  private stripe;

  private authService: AuthService;
  private router: Router;
  private seoService: SeoService;
  
  private trees = 0;
  private CO2 = 0;
  private land = 0;
  
  public projects?: SelectedProject[] = [];
  public projectsSection?: Project[];
  public plan?: Plan;
  public createImpactText = createImpactText;
  public editDetails = false;
  public editPayment = false;
  public cancelPlan = false;
  public paymentError = '';
  public detailsError = '';
  public cancelError = '';
  public warning = '';
  public noPlan = false;
  public name: string;
  private impacts: ImpactData[] = [];
  public open = false;
  public modal = false;
  public details?: number;
  public detailsName?: string;
  public id: string;
  public business = false;
  public voucher = false;
  public info = false;
  public displayed: AccountContent = 'dashboard';
  public estateAgent = false;
  public winkworth = false;
  public businessPurchases: BusinessPurchase[];
  public recentBusinessPurchases: BusinessPurchase[];
  public getProjectUrl = getProjectUrl;
  public businessLogo: string;
  public getBusinessName = getBusinessName;
  public getBusinessLogo = (name: string): string => {
    return this.businessLogo || '../../../assets/' + getBusinessLogo(name);
  };
  public levelLogo: string;
  public level: Level;
  public levelPopup = false;

  public totalTrees = '';
  public totalCO2 = '';
  public totalLand = '';
  public maxTotalsLength = 0

  public contactUs = false;
  
  public accountSteps: AccountStep[];
  public currentStep: AccountStep;
  public setupPercentage: number;
  public poll?: string;

  public ttConfig = {
    'placement': 'top',
    'hideDelay': 0,
  }
 
  constructor(authService: AuthService, router: Router, seoService: SeoService) {
    this.authService = authService;
    this.router = router;
    this.seoService = seoService;
   }

  ngOnInit(): void {
    // Hack
    setTimeout(() => {
      this.stripe = Stripe('pk_live_51IX4vmBDboGHB3gmCr8cmjFzg0aO9LxJHErrbE8FAtOh806Mb3PWjkPFR3A6g4z4ySI1mlfoToieenXke3iuFP1A00zZvlxg13');
      //private stripe = Stripe('pk_test_51IX4vmBDboGHB3gmxC86oDlimVDH8gS6qAZ0QFAT6BtWq2aYrO4VCosc457AOLotPFFMfM0hK7bOTlgLMxzwQgYT00rY5mAx2D');
    }, 200);

    this.seoService.setSeo('Account', 'Track your cumulative impact, find out more about the details of each project, and change the projects your support via our Account page.');

    this.authService.getAccountName().subscribe(
      (res) => {
        this.name = res.user.name;
      },
      (err) => {
        this.router.navigate(['/login']);
      }
    );
    combineLatest([
      this.authService.getPlan(),
      this.authService.getPamentStatus(),
      this.authService.isEstateAgent(),
      this.authService.isWinkworth(),
    ]).subscribe(([data, paymentStatus, estateAgent, winkworth]) => {
      this.estateAgent = estateAgent;
      this.winkworth = winkworth;
      this.id = data[0]._id;
      this.business = data[0].business;
      this.businessLogo = data[0].businessLogo;
      this.poll = data[0].poll;

      if(!this.business) {
        if (data[0].purchases) {
          this.calculatePurchasesImpact(data[0].purchases);
        }
        
        if (!data[0].planSummary) {
          this.noPlan = true;
          return;
        }

        this.projectsSection = [...data[0].planSummary.projects];
        this.plan = plans.find((plan) => {
          return plan.name === data[0].planSummary.plan;
        });

        this.calculateTotalImpact(data[0].planSummaries);

        this.addBonusTrees(data[0].trees);

        if (paymentStatus === false) {
          this.warning = 'There was an error with your payment. Please enter new card details below';
        }
      } else {
        this.noPlan = true;

        combineLatest([
          this.authService.getLevelLogo(),
          this.authService.getLevel(),
        ]).subscribe(([logo, level]) => {
          this.levelLogo = logo;
          this.level = level;
          this.levelPopup = !!data[0].levelPopup;
        });

        this.businessPurchases = data[0].purchases;

        if(this.businessPurchases) {
          this.calculateBusinessImpact(data[0].purchases);
        }
        if (estateAgent && !this.businessPurchases) {
          this.voucher = true;

          if (localStorage.getItem('business-popup') !== 'false') {
            this.info = true;
            localStorage.setItem('business-popup', 'false');
          }

          this.authService.getExampleAccount().subscribe((data) => {
            this.businessPurchases = data;
            this.calculateBusinessImpact(data);
          });
        }
        if (CAVENDISH.includes(this.name) && !this.businessPurchases) {
          this.voucher = true;
          this.authService.getExampleAccount().subscribe((data) => {
            this.businessPurchases = data;
            this.calculateBusinessImpact(data);
          });
        }

        if(winkworth) {
          this.getAccountSteps();
        }
      }
    });
  }

  public updatePayment(event: any): void {
    this.authService.updatePayment(event).subscribe((result) => {
      if(result.status === 'active') {
        this.router.navigate(['success', 'payment']);
      } else if(result.latest_invoice.payment_intent.status === 'requires_payment_method') {
        this.paymentError = 'There was an error with your card. Please try again'
      } else if (result.latest_invoice.payment_intent.status === 'requires_action') {
        this.stripe.confirmCardPayment(result.latest_invoice.payment_intent.client_secret, {
          payment_method: event,
        })
      }
    });
  }

  public logout(): void {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  public cancel(): void {
    this.authService.cancelSubscription().subscribe(
      (res) => {
        this.router.navigate(['/']);
      },
      (err) => {
        if(err.status === 404) {
          this.cancelError = 'Error: There is no existing plan to delete.'
        } else {
          this.cancelError = 'There was an error deleting your plan.'
        }
      }
    );
  }

  private calculateTotalImpact(planSummaries: PlanSummary[]): void {
    if(planSummaries) {
      for(const planSummary of planSummaries) {
        for(const project of planSummary.projects) {
          const existingProject = this.impacts.find(impact => impact.id === project.id);
          const currentProject = this.projects.find(impact => impact.id === project.id);
          const value = project.tokens * project.multiplier;
          if(existingProject) {
            existingProject.value = existingProject.value + value;
          } else {
            this.impacts.push({id: project.id, value: value});
          }

          this.addToTotalImpacts(project.id, value);

          if(!currentProject) {
            this.projects.push(project);
          } else {
            currentProject.tokens = currentProject.tokens + project.tokens;
          }
        }
      }
    }

    this.projects = this.projects.filter((project) => !!this.impacts.find((impact) => impact.id === project.id));
  }

  private addToTotalImpacts(projectId: number, value: number) {
    const treeProjects = [1, 2, 3];
    const solarLights = 6;
    const bees = 4;
    const cookStoves = 7;
    const peatland = 11;

    if(treeProjects.includes(projectId)) {
      this.trees = this.trees + value;
      this.CO2 = this.CO2 + (0.150 * value);
      this.land = this.land + value;
    }

    if(projectId === solarLights) {
      this.CO2 = this.CO2 + (0.22 * value);
    }

    if(projectId === bees) {
      this.land = this.land + value;
    }

    if(projectId === cookStoves) {
      this.CO2 = this.CO2 + 5 * value;
    }

    if (projectId === peatland) {
      this.CO2 = this.CO2 + (0.0001 * value);
      this.land = this.land + value;
    }

    this.totalTrees = this.trees.toString();
    this.totalCO2 = (this.CO2.toFixed(1)).toString() + 't';
    this.totalLand = this.land.toString() + 'm\u00b2';

    this.maxTotalsLength = Math.max(this.totalTrees.length, this.totalCO2.length, this.totalLand.length);
  }

  private calculatePurchasesImpact(purchases: CartItem[][]): void {
    for (const purchase of purchases) {
      for (const cartItem of purchase) {
        let projectId = PROJECT_GROUPS.find((projectGroup) => projectGroup.id === cartItem.groupId).projectId;
        if(!projectId) {
          if(cartItem.groupId === 1) {
            if(cartItem.location === 'Mozambique') {
              projectId = 1;
            }
            
            if(cartItem.location === 'Madagascar') {
              projectId = 2;
            }
            
            if(cartItem.location === 'Kenya') {
              projectId = 3;
            }
          }
        }
        const existingProject = this.impacts.find(impact => impact.id === projectId);
        const currentProject = this.projects.find(impact => impact.id === projectId);

        let value;

        if(projectId === 5) {
          value = cartItem.value / 100;
        } else {
          value = cartItem.value;
        }

        value = value * cartItem.quantity;

        if(existingProject) {
          existingProject.value = existingProject.value + value;
        } else {
          this.impacts.push({id: projectId, value: value});
        }

        this.addToTotalImpacts(projectId, value);
        if(!currentProject) {
          const projectTemplate = {
            ...projects.find(project => project.id === projectId),
            tokens: this.calculateTokens(projectId, value),
          };
          this.projects.push(projectTemplate);
        } else {
          currentProject.tokens = currentProject.tokens + this.calculateTokens(projectId, value);
        }
      }
    }
  }

  private addBonusTrees(trees?: number): void {
    const projectId = 3;
    if(!trees) {
      return
    }

    const existingProject = this.impacts.find(impact => impact.id === projectId);
    const currentProject = this.projects.find(impact => impact.id === projectId);

    if (existingProject) {
      existingProject.value = existingProject.value + trees;
    } else {
      this.impacts.push({ id: projectId, value: trees });
    }

    this.addToTotalImpacts(projectId, trees);
    if (!currentProject) {
      const projectTemplate = {
        ...projects.find(project => project.id === projectId),
        tokens: this.calculateTokens(projectId, trees),
      };
      this.projects.push(projectTemplate);
    } else {
      currentProject.tokens = currentProject.tokens + this.calculateTokens(projectId, trees);
    }

  }

  private calculateBusinessImpact(purchases: BusinessPurchase[]): void {
    this.recentBusinessPurchases =
      this.businessPurchases
        .sort((a, b) => {
          return -a.date.toString().localeCompare(b.date.toString());
        }).slice(0, 5);

    for (const purchase of purchases) {
      const existingProject = this.impacts.find(impact => impact.id === purchase.projectId);
      const currentProject = this.projects.find(impact => impact.id === purchase.projectId);

      let value;

      if (purchase.projectId === 5) {
        value = purchase.value / 100;
      } else {
        value = purchase.value;
      }

    
      if (existingProject) {
        existingProject.value = existingProject.value + value;
      } else {
        this.impacts.push({ id: purchase.projectId, value: value });
      }

      this.addToTotalImpacts(purchase.projectId, value);
      if (!currentProject) {
        const projectTemplate = {
          ...projects.find(project => project.id === purchase.projectId),
          tokens: this.calculateTokens(purchase.projectId, value),
        };
        this.projects.push(projectTemplate);
      } else {
        currentProject.tokens = currentProject.tokens + this.calculateTokens(purchase.projectId, value);
      }
    }
  }

  private calculateTokens(projectId: number, value: number): number {
    if(projectId === 1 || projectId === 2 || projectId === 3) {
      return value/10;
    }

    if(projectId === 4) {
      return Math.round((value/6) * 10) / 10;
    }

    if(projectId === 5) {
      return value;
    }

    if(projectId === 6) {
      return value * 2.5;
    }

    if (projectId === 7) {
      return value * (12/2);
    }

    if (projectId === 8 || projectId === 9) {
      return Math.round((value / 6) * 10) / 10;
    }
  }

  public getImpactText(project: SelectedProject | number) {
    return TOTAL_IMPACT_TEXTS.find(impact => impact.id === (typeof project === 'number' ? project : project.id)).text;
  }

  public getImpactValue(project: SelectedProject): number {
    const impact = this.impacts.find(impact => impact.id === project.id);
    if (!impact) {
      return 0;
    } else {
      return Math.floor(impact.value);
    }
  }

  public getProjectIndexArray(): number[] {
    const indexArray = [];
    
    for(let i = 0; i < this.projects?.length; i++) {
      indexArray.push(i);
    }
    
    return indexArray;
  }

  public getDetailsText() {
    const tokens = this.projects.find(project => this.details === project.id).tokens;
    return IMPACT_DETAILS.find((details) => details.id === this.details).generateText(tokens, this.id);
  }

  public getDetailsTextDT(projectId: number): string {
    const tokens = this.projects.find(project => projectId === project.id).tokens;
    return IMPACT_DETAILS.find((details) => details.id === projectId).generateText(tokens, this.id);
  }

  public resetPopup(): void {
    this.levelPopup = false;
    this.authService.resetPopup().subscribe();
  }

  private getAccountSteps(): void {
    if(!this.winkworth) {
      return;
    }
    
    this.authService.getAccountSteps().subscribe((res) => {
      this.accountSteps = res;

      this.currentStep = this.accountSteps.filter((accountStep) => {
        return !accountStep.steps.map((step) => step.done).reduce((accumulator, currentValue) => accumulator && currentValue);
      })[0];

      this.setupPercentage = getAccountStepsPercentage(this.accountSteps);
    });
  }

  public updateAccountSteps(step) {
    step.done = true;
    this.authService.updateAccountSteps(this.accountSteps).subscribe(() => {
      this.getAccountSteps();
    });
  }

  public print(arg: string) {
    console.log(arg);
  }
}
