import { Component, Input, OnInit } from '@angular/core';
import { Project, projects } from 'server/projects';
import { SeoService } from 'src/app/services/seo.service';
import { SelectedProject } from '../choose-plan/choose-plan.component';
import { FaqInput } from '../faq/faq.component';

type StatusString = "active" | "in-development";

interface Status {
  name: StatusString;
  displayName: string;
}

export interface Bio {
  projectId: number;
  name: string;
  thumbnail: string;
  tokens?: number;
  faqs?: FaqInput[];
  gallery: string[];
  title: string;
  description: string;
  url: string;
  content: BioContent[];
  summary: BioSummary;
  video?: string;
  status: Status;
}

interface BioSummary {
  ecoCoins: number,
  impact: string,
  bullets: string[];
  images: string[];
  quote: BioQuote;
}

interface BioContentBase {
  type: 'text' | 'quote' | 'subtitle' | 'html' | 'image' | 'list' | 'google-maps' | 'image-and-text';
}

type BioContent = BioText | BioImage | BioQuote | BioGoogleMaps | BioList | BioImageAndText;

interface BioText extends BioContentBase {
  type: 'text' | 'subtitle';
  data: string;
}

interface BioQuote extends BioContentBase {
  type: 'quote';
  quote: string;
  author: string;
}

interface BioImage extends BioContentBase {
  type: 'image';
  image: string;
  caption: string;
}

interface BioList extends BioContentBase {
  type: 'list';
  text: string[];
}

interface BioGoogleMaps extends BioContentBase {
  type: 'google-maps'
}

interface BioImageAndText extends BioContentBase {
  image: string;
  text: string;
}

const treesFaqs: FaqInput[] = [
  {
    dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
    text: 'All our environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline turq-text" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
      + 'All of our tree-planting projects have a directly address <span class="bold italic">two</span> of these crises - the nature crisis and the climate crisis.<br/><br/>'
      + 'Our trees address the <span class="green bold">nature crisis</span>, in that they increase local biodiversity and are a form of afforestation.<br/><br/>'
      + 'They also have a direct positive impact on the <span class="red bold">climate crisis</span>. This is because the mangrove trees we plant (which make up most of the trees planted) are chosen partly because they sequester a <span class="italic">huge amount of carbon dioxide</span> from the atmosphere. This is because they have deep roots where the carbon is stored.<br/><br/>'
      + 'This makes them a highly effective tool at counteracting humanity’s huge carbon output, which is fueling global warming and climate change. '
  },
  {
    dropdown: 'Are the trees protected once they are planted?',
    text: 'The short answer is, yes.<br/><br/>'
      + 'But we wanted some more detailed, so we asked out tree-planting partner, <span class="italic">Eden Reforestation</span>. <br/>Here’s what they said: <br/><br/>'
      + '“We make every effort to ensure the forest we plant becomes permanent and sustainable. Towards this end, we have implemented the following steps:<br/><br/>'
      + '•&nbsp;&nbsp;We work carefully with all levels of the government to secure written agreements designating the restoration sites as protected in perpetuity.<br/><br/>'
      + '•&nbsp;&nbsp;We do not plant in logging areas. We do everything within legal limits to ensure the restoration sites are guaranteed to stand in perpetuity.<br/><br/>'
      + '•&nbsp;&nbsp;We hire locals to plant the trees. In this way, we alleviate extreme poverty within the impacted community. The community members now have an economic incentive to ensure the well-being of the restoration project. They also have a sense of “ownership” over the trees and restored forest, and they protect it with great care.<br/><br/>'
      + '•&nbsp;&nbsp;We plant agroforestry species (fruit, fodder, and construction species designed to provide food security and benefit legitimate human needs). Over time these trees become a source of sustainable income.<br/><br/>'
      + '•&nbsp;&nbsp;We do all possible to supply the locals with alternative fuel sources (fuel-efficient dry wood stoves and solar parabolic stoves), which reduces and/or eliminates their dependence on charcoal.<br/><br/>'
      + '•&nbsp;&nbsp;We hire forest guards as part of the labor force. We have recently created a Forest Guard Endowment Fund for long-term guarding and protection of our sites.<br/><br/>'
      + '•&nbsp;&nbsp;Most significantly, we have seen the locals fall in love with their forest. They also recognize and benefit from the restored forest through increased fisheries, improved farming, cleaner water, and the formation of microenterprises.”'
  },
  {
    dropdown: 'How can you prove that the trees are actually being planted?',
    text: 'We work closely with our tree-planting partners, Eden Reforestation Projects, to make sure that all our trees are planted and looked after during their lifetimes.<br/><br/>'
      + 'Eden’s planting sites are routinely audited by third parties to check that the correct number of trees have been planted, and that they are healthy. <br/><br/>'
      + 'With over two decades of experience and one of the largest networks of planting sites in the world, they are highly experienced and have a great track record when it comes to tracking their impact on the ground.<br/><br/>'
      + 'We also publicly display all the purchase certificates, dates and locations of all trees funded by the Greenspace Community, in our <a class="bold turq-text underline" href="https://docs.google.com/spreadsheets/d/1A7nLmHQWvBZgTqCxm1EUVCQlY1agC3DLpNkOFhR-hT4/edit#gid=1599391297" target="_blank">impact records</a>.'
  },
  {
    dropdown: 'How much CO2 do these trees absorb?',
    text: 'Trees are <a class="bold turq-text underline" href="https://www.theguardian.com/environment/2019/jul/04/planting-billions-trees-best-tackle-climate-crisis-scientists-canopy-emissions" target="_blank">one of the best tools we have</a> to remove (’sequester’ is the technical term) carbon pollution from our atmosphere.<br/><br/>'
      + 'We record that our trees absorb 150kg of CO2 over the course of their lifetimes. This is a conservative estimate based on surveys of the trees we plant.<br/><br/>'
      + 'However, we estimate that many of our trees may sequester <a class="bold turq-text underline" href="https://iopscience.iop.org/article/10.1088/1748-9326/aa9f03/pdf" target="_blank">much more carbon dioxide</a> than this.<br/><br/>'
      + 'This is because many of the trees we plant are <span class="bold">mangrove forests</span>, whose deep roots enable them to sequester more than 150kg of CO2 over the course of their lifetimes. '
  },
  {
    dropdown: 'What are the benefits of tree-planting?',
    text: '<span class="bold">Socio-economic benefits</span><br/><br/>'
      + '•&nbsp;&nbsp;Empowers local people and helps reduce poverty.<br/><br/>'
      + '•&nbsp;&nbsp;Provides stable employment and a sustainable source of income.<br/><br/>'
      + '•&nbsp;&nbsp;This income allows parents to send their children to schools, afford life-saving medicines and build their homes.<br/><br/>'
      + '•&nbsp;&nbsp;Tree planting helps to regenerate the soil, which in turn makes the surrounding land more fertile for growing crops, making it a better source of food and future income.<br/><br/>'
      + '•&nbsp;&nbsp;Reforestation has secondary affects of providing reliable, clean water supplies, and reducing the risk of landslides by stabilising the soil.<br/><br/>'
      + '<span class="bold">Environmental benefits</span><br/><br/>'
      + '•&nbsp;&nbsp;Reduces the risk of flooding and landslides.<br/><br/>'
      + '•&nbsp;&nbsp;Restores and expands vital habitats for local animals, insets and other plants, thus increasing biodiversity.<br/><br/>'
      + '•&nbsp;&nbsp;Trees - especially the trees we plant - absorb CO2 from the atmosphere, helping to address the climate crisis.<br/><br/>'
      + '•&nbsp;&nbsp;Trees prevent soil erosion and improve soil quality.'
  },
  {
    dropdown: 'Do all the trees survive?',
    text: 'It is inevitable that newly planted trees do not always survive.<br/><br/>'
      + 'Our tree-planting partner estimates up to 20% of all trees planted will die. However, the trees we plant naturally regenerate - producing their own saplings as they grow bigger - which results in <span class="italic bold">more trees</span> than were initially planted.<br/><br/>'
      + '<span class="italic">Eden Reforestation</span> writes of the Kenyan mangrove sites:<br/><br/>'
      + '“natural regeneration typically exceeds 150% of the original number planted. The same is true of the dry deciduous sites in Madagascar, and we already see the same multiplication effect in Nepal.”'
  },
  {
    dropdown: 'Are the trees planted native to the local ecoystem?',
    text: 'None of our tree-planting projects are monoculture plantations, and none of them introduce invasive species.<br/><br/>'
      + 'They only ever involve a range of <span class="bold italic">native</span> species designed to complement the local habitat.<br/><br/>'
      + 'More than this, to protect against the threat of deforestation, a percentage of <span class="italic">agroforestry</span> species are also planted, which provide fruits and medicines for the local community.<br/><br/>'
      + 'This practice benefits the local community, and provides an insentive to protect the newly planted trees.'
  },
  {
    dropdown: 'Are workers being cared for and paid fairly?',
    text: 'One of the most important aspects of our tree planting projects is supporting the local community and engaging them in the tree planting itself.<br/><br/>'
      + 'Our tree planting partner, <span class="italic">Eden Reforestation</span>, provide fair wages to many impoverished locals or at least the minimum wage, including holiday pay.<br/><br/>'
      + 'Deforestation is often caused by the local community. By paying wages, locals no longer are forced to cut down trees to produce marketable goods to make ends meet. Reforestation forges a deep connection between the villagers and their land, which helps protect that land.<br/><br/>'
      + 'More than this, Eden go above and beyond to care for their community of planters. They have dug wells for clean water, provided food, blankets and emergency assistance during torrential rains, and even built schools.<br/><br/>'
      + 'We believe that all of this is what a responsible reforestation project should look like.'
  }
];

export const BIOS: Bio[] = [
  {
    projectId: 1,
    url: 'tree-planting-in-nepal',
    name: 'Tree Planting in Nepal',
    thumbnail: '1-1.png',
    status: {
      name: "active",
      displayName: "Active"
    },
    faqs: treesFaqs,
    gallery: [
      '../../../assets/1-1.png',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/20191010_jhapa-nepal_0007.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/20190822_Mozambique_0333.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/ampasimarine-dustband.jpg',
    ],
    title: 'Tree planting in Nepal',
    description: 'Learn more about Eden Greenspace’s tree-planting projects in Nepal, and how you can help to reduce poverty and restore forests on the ground.',
    summary: {
      ecoCoins: 1,
      impact: 'Plant 10 trees',
      bullets: [
        "Less than 30% of Nepal's forests remain.",
        '81% of the population in Nepal live in rural areas.',
        "27 endangered mammal species call Nepal's forests their home.",
        'Being one of the least developed countries in the world, communities depend upon the natural environment for food, shelter, and income, resulting in ecosystem pressurisation, deforestation and decline.',
        'We are standing in the way of this forest decline, with reforestation planting sites located in the mountainous Nawalparasi District to the lowland alluvial plains in the Terai Region and around Chitwan National Park.',
        'We have been part of the 12 million+ and counting trees produced, planted, and protected in Nepal, with 380+ local forest steward employees empowered with fair wages.',
        'We plant a range of hardy mountainous tree species who sequester at least 150kg of CO2 per tree. ',
        'This project directly addresses the UNEP’s nature and climate crises.',
      ],
      images: [
        'sdg1.png',
        'sdg11.png',
        'sdg13.png',
        'sdg15.png',
      ],
      quote: {
        type: 'quote',
        quote: '“Trees and forests play an essential role in mitigating the impact of climate change. Planting trees is one of the most important things we can do to contribute to the health of the planet.”',
        author: '- Antonio Guterres, Secretary General of the UN - 2018',
      }
    },
    content: [
      {
        type: 'google-maps',
      },
      {
        type: 'image',
        image: '1-2.png',
        caption: "Nepal - one of the worlds ‘least’ developed countries, straddling the Himalayas between the major powers of India and China.",
      },
      {
        type: 'image',
        image: '1-3.png',
        caption: "Areas of forestry coverage in the country surveyed in 2020. Red indicates the vast areas of land that once had tree cover and has since been deforested. Green indicates current forest, and the beige and grey are barren mountainous regions unsuitable for plant growth.",
      },
      {
        type: 'image',
        image: '1-4.png',
        caption: "Being heavily dependant upon natural resources, the areas around major population centres, surrounding the largest population centre in the country (Kathmandu) have experienced accordingly high levels of deforestation. ",
      },
      {
        type: 'text',
        data: 'The northern Chitwan forest region around Bharatpur (where one of our reforestation sites is) is being encroached upon by deforestation, making our work to plant trees in that location, all the more critical.',
      },
      {
        type: 'subtitle',
        data: 'Tree planting in Nepal'
      },
      {
        type: 'image',
        image: '1-5.png',
        caption: "At our Grasslands planting site in Jhapa",
      },
      {
        type: 'text',
        data: 'Jhapa is located along the Southeastern tip of Nepal, bordering India. It is characterized by flat grasslands and dense forest. At this site we work with local people and community leaders to restore previously forested land in the region.',
      },
      {
        type: 'text',
        data: '<span class="underline">We plant a range of hardy tree species which sequester 150kg of CO2 per tree on average, these species include:</span>'
      },
      {
        type: 'list',
        text: [
          'Acacia katechu(Khair)',
          'Adina cordifolia(Karma / Haldu)',
          'Albizia spps(Siris)',
          'Alnus nepalensis(Utis)',
          'Brassiopsis hainla(Chuletro)',
          'Chukrasia tabularis(Chekrasi)',
          'Cordia dichtoma(Bohori)',
          'Dalbergia latifolia(Satisal)',
          'Dendrocalamus(Bamboo)',
          'Fraxinus floribunda(Lakhuri)',
          'Gogan',
          'Latahar',
          'Litsea monopetala(Kutmero)',
          'Maleta',
          'Michelia champaca(Champ)',
          'Paile',
          'Pterocarpus marsupium(Bijaya sal)',
          'Shorea robusta(Sal)',
          'Shorea seminis(Malaysian sal)',
          'Tectona grandis(Teak)',
          'Terminalia bellerica(Barro)',
        ],
      },
      {
        type: 'image',
        image: '1-6.png',
        caption: "Nawalparasi planting site which is located in the Western region of Nepal in the Himalayan Mountains.",
      },
      {
        type: 'text',
        data: 'Nepal is a diverse nation with snow-capped mountains to the north and tropical plains to the south.'
      },
      {
        type: 'text',
        data: 'This is in challenging terrain with a highly active tectonic region which is unstable in deforested areas. New trees provide great benefits in anchoring the soil and protecting the villages from environmental disasters'
      },
      {
        type: 'image',
        image: '1-7.png',
        caption: "Tree planter carries sapling offshoots in the Chitwan National Park",
      },
      {
        type: 'text',
        data: 'Chitwan National Park (our planting site in the south of the country) alone hosts more than 700 species of wildlife, including leopards and the Bengal tiger.'
      },
      {
        type: 'text',
        data: 'It is also home to various cultures united by their close relationship with nature that have remained insulated from much of the socioeconomic development enjoyed in urban regions. '
      },
      {
        type: 'text',
        data: 'Yet, they are subject to more significant environmental hazards, perpetuating high levels of inequality.'
      },
      {
        type: 'text',
        data: 'All of our tree-planting projects have a directly address <span class="bold italic">two</span> of these crises - the nature crisis and the climate crisis.'
      },
      {
        type: 'text',
        data: 'Our trees address the <span class="green bold">nature crisis</span>, in that they increase local biodiversity and are a form of afforestation.'
      },
      {
        type: 'text',
        data: 'They also have a direct positive impact on the <span class="red">climate crisis</span>. This is because the mangrove trees we plant (which make up most of the trees planted) are chosen partly because they sequester a <span class="italic">huge amount of carbon dioxide</span> from the atmosphere. This is because they have deep roots where the carbon is stored.'
      },
      {
        type: 'text',
        data: 'This makes them a highly effective tool at counteracting humanity’s huge carbon output, which is fueling global warming and climate change.'
      },
      {
        type: 'subtitle',
        data: 'By choosing one of our subscription plans, you can plant up to 80 trees per month in Nepal!'
      },
      {
        type: 'text',
        data: 'Help us support these Nepalese  communities, improve Nepals ecosystem, and contribute to saving our planet from climate change.'
      },
      {
        type: 'text',
        data: 'Find out more about the impact of our tree planting, how the trees are protected and how our tree planters are supported in the FAQ’s below!'
      },
    ],
  },
  {
    projectId: 2,
    url: 'tree-planting-in-madagascar',
    name: 'Tree Planting in Madagascar',
    thumbnail: '2-1.jpg',
    status: {
      name: "active",
      displayName: "Active"
    },
    faqs: treesFaqs,
    gallery: [
      '../../../assets/2-1.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/Madagascar_2013_Young+mangrove-forest.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/madagascar_wm.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/forest.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/IMG_4873.jpg',
    ],
    title: 'Tree planting in Madagascar',
    description: 'Learn more about Eden Greenspace’s tree-planting projects in Madagascar, and how you can help to reduce poverty and restore forests on the ground.',
    summary: {
      ecoCoins: 1,
      impact: 'Plant 10 trees',
      bullets: [
        'Madagascar is one of the world’s top biodiversity conservation priorities because of its endemic species and severe habitat loss rates.',
        '75% of species are unique to Madagascar, yet only 10% of primary forests remain!',
        'We work with local communities in Northwest Madagascar to restore forests on a massive scale, thereby creating jobs, protecting ecosystems, and helping mitigate climate change.',
        'We plant Mangrove trees, with each tree sequestering at least 150kg of CO2 over the course of their life.',
        'These Mangrove forests also providing essential stability against soil erosion and improving ocean health by filtering out toxins through their root systems.',
        'This project is part of the 776 million+ trees produced, planted, and protected and 11,700+ employees empowered with fair wages.',
        'Our partners on the ground have extensive infrastructure such as guardhouses, fire towers, and seed banks to nurture our planted trees to maturity.',
        'Our partners on the ground have extensive infrastructure such as guardhouses, fire towers, and seed banks to nurture our planted trees to maturity.',
      ],
      images: [
        'sdg1.png',
        'sdg11.png',
        'sdg13.png',
        'sdg15.png',
      ],
      quote: {
        type: 'quote',
        quote: '“Trees and forests play an essential role in mitigating the impact of climate change. Planting trees is one of the most important things we can do to contribute to the health of the planet.”',
        author: '- Antonio Guterres, Secretary General of the UN - 2018',
      }
    },
    content: [
      {
        type: 'subtitle',
        data: 'Planting trees in Madagascar',
      },
      {
        type: 'text',
        data: '90% of plant and animal species from Madagascar are endemic, and almost all of them are endangered. This is due to the vast deforestation of Madagascar’s forests, such as the Kirindi Forest, leading to the destruction of shelter, food sources, and sanctuary from humans. In fact, only 10% of Madagascan forests remain, making this one of the most deforested islands in the world.',
      },
      {
        type: 'image',
        image: '2-2.jpeg',
        caption: 'Deforestation in the Kirindi Forest, Madagascar',
      },
      {
        type: 'text',
        data: 'Through our partnership with <a target="_blank" href="https://edenprojects.org/">Eden Reforestation Projects</a>, a world-leading reforestation charity, you can help to reduce poverty and restore forests in Madagascar.'
      },
      {
        type: 'text',
        data: 'As of December 2020, Eden have planted 485 million trees, and aim to reach 500 million by 2025. Through their life-changing actions, Eden provides many social and environmental benefits such as offering employment in poverty-stricken states, protecting biodiversity, and fighting against climate change.',
      },
      {
        type: 'google-maps',
      },
      {
        type: 'image',
        image: '2-3.png',
        caption: "Madagascar's northern coastline planting sites.",
      },
      {
        type: 'image',
        image: '2-4.png',
        caption: 'Northern madagascan peninsula overlay of mangrove coverage and regions of mangrove decline.',
      },
      {
        type: 'image',
        image: '2-5.png',
        caption: 'North-western coastline with overlay of mangrove coverage, highlight the importance of mangroves for coastal defence, especially as these coastal regions are low lying and exposed to rising sea levels. Patchy mangrove coverage along the western coastline.',
      },
      {
        type: 'text',
        data: 'Madagascar is situated off the coast of Mozambique and is the second largest island country in the world, with a population of around 28 million people in 2021, however it is also one of the least developed countries in the world.'
      },
      {
        type: 'text',
        data: '<span class="underline">We plant a diverse cocktail of tree species to promote biodiversity, with a average sequestration rate of 150kgs of CO2 across the species, which include:</span>'
      },
      {
        type: 'list',
        text: [
          'Adansonia sp',
          'Albizia saman',
          'Albizzia lebeck',
          'Anacardium occidentale',
          'Annona squamosa',
          'Aphananthe sakalava',
          'Baudouinia flugeiformis',
          'Bismarckia nobilis',
          'Breonadia salincina',
          'Bussela perieri',
          'Canarium',
          'Carica papaya',
          'Cedrelopsis grevei',
          'Ceiba pentandra',
          'Cerbera manghas',
          'Commiphora guillauminii',
          'Commiphora madagascariensis',
          'Commiphora Pervilleana',
          'Coptosperma madagascariense',
          'Dalbergia chlorocarpa',
          'Diospyros aculeata',
          'Diospyros sp.',
          'Diospyros tropohylla',
          'Dupuya madagascariensis',
          'Entada leptostachya',
          'Erythroxylum nitidulum',
          'Grewia',
          'Khaya madagascariensis',
          'Mangifera indica',
          'Moringa oleifera',
          'Pachypodium rutembergianum',
          'Paracarphallea pervilleana',
          'Poupartia birrea caffre',
          'Poupartia silvatica',
          'Rhopalocarpus similis',
          'Rouvafia',
          'Samanea Saman',
          'Sclerocarya birrea subsp. Caffra',
          'Sclerocroton melanostictus',
          'Sorindeia',
          'Stereospermum euphorioides',
          'Strychnos',
          'Strychnos madagascariensis',
          'Symphonia',
          'Syzygium cumini',
          'Tamarindus indica',
          'Tectona grandis',
          'Terminalia mantaly',
          'Treculia perrieri',
          'Zatropha',
        ],
      },
      {
        type: 'text',
        data: '<span class="underline">And the mangroves species include:</span>'
      },
      {
        type: 'list',
        text: [
          'Avincinia marina',
          'Bruguiera gumnooshiza',
          'Ceriops tagel',
          'Rhizophora mucronata',
        ],
      },
      {
        type: 'text',
        data: 'Eden Reforestation Projects work primarily in the North West of the island, in both coastal and inland environments.'
      },
      {
        type: 'image',
        image: '2-6.png',
        caption: 'Destroyed mangrove forest near Vatoavo Village, Madagascar',
      },
      {
        type: 'text',
        data: 'Habitat diversity in Madagascar means there is the possibility for a huge array of wildlife, and this was once the case, however today many of these beautiful landscapes have been destroyed for agricultural use.'
      },
      {
        type: 'text',
        data: 'The Madagascan Mangroves, listed as one of the most outstanding eco-regions according to the WWF, are now in critical condition.'
      },
      {
        type: 'text',
        data: 'Many of the workers are single mothers or members of poor communities, and so the project has been vital for the economic development of the island’s communities.',
      },
      {
        type: 'text',
        data: 'Many of the workers are single mothers or members of poor communities, and so the project has been vital for the economic development of the island’s communities.',
      },
      {
        type: 'text',
        data: 'All of our tree-planting projects have a directly address <span class="bold italic">two</span> of these crises - the nature crisis and the climate crisis.',
      },
      {
        type: 'text',
        data: 'Our trees address the <span class="bold green">nature crisis</span>, in that they increase local biodiversity and are a form of afforestation. ',
      },
      {
        type: 'text',
        data: 'They also have a direct positive impact on the <span class="bold red">climate crisis</span>. This is because the mangrove trees we plant (which make up most of the trees planted) are chosen partly because they sequester a <span class="italic">huge amount of carbon dioxide from the atmosphere</span>. This is because they have deep roots where the carbon is stored.',
      },
      {
        type: 'text',
        data: 'This makes them a highly effective tool at counteracting humanity’s huge carbon output, which is fuelling global warming and climate change.',
      },
      {
        type: 'image',
        image: '2-7.png',
        caption: 'Villagers who work for our project partners on the ground (Eden Reforestation Projects) planting trees in North West Madagascar',
      },
      {
        type: 'subtitle',
        data: 'By choosing one of our subscription plans, you can plant up to 80 trees per month!',
      },
      {
        type: 'text',
        data: 'Help us support the local communities, improve the Madagascan ecosystem, and contribute to saving our planet from climate change.',
      },
      {
        type: 'text',
        data: 'Find out more about the impact of our tree planting, how the trees are protected and how our tree planters are supported in the FAQ’s below!',
      }
    ]
  },
  {
    projectId: 9,
    url: 'coral-reef-protection-in-hawaii',
    name: 'Coral Reef Protection in Hawaii',
    thumbnail: '9-1.jpg',
    status: {
      name: "active",
      displayName: "Active"
    },
    faqs: [
      {
        dropdown: 'How much does it cost to protect 1 m2 of coral reef habitat?',
        text: 'You can protect 1m2 of coral reef habitat for £0.30 per m2, either by signing up to one of our <a href="/choose-your-plan" class="turq-text bold underline pointer">individual subscription plans</a>, or through a <a href="/business" class="turq-text bold underline pointer">business subscription</a>.<br/><br/>'
          + 'We do not currently sell bracelets for our coral reef projects, but you can purchase our tree-planting bracelets <a class="bold turq-text pointer underline" href="/products/eden-bracelet">here.</a>'
      },
      {
        dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
        text: 'All our environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline turq-text" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
          + 'All of our tree-planting projects have a directly address <span class="bold italic">two</span> of these crises - the nature crisis and the climate crisis.<br/><br/>'
          + 'Our coral reef projects primarily address the nature crisis, as they are focused on restoring coral reef ecosystems and increasing biodiversity in this rich marine habitat.<br/><br/>'
          + 'However, coral reef habitats also address the climate crisis, as they are natural carbon sinks (they store carbon in their skeletons). Similarly, our coral reef project in Hawaii focuses on reducing pollution, as sediment runoff is a major source of coral bleaching.'
      },
      {
        dropdown: 'Why is restoring coral reefs better than just planting more corals?',
        text: 'To give you the best possible justification for why our approach to restoring corals is better than planting new ones, we asked our partners over at the Coral Reef Alliance (CORAL) for their reasoning. This is what they said:<br/><br/>'
          + '<span class="italic">“While many other non-profits take this approach to supporting a healthier future for reefs through planting, we take an alternative approach of conservation by tackling the direct threats to coral reefs: poor water quality, unsustainable fishing practices, ineffective or insufficient governance of marine protected areas, and unsustainable tourism practices.  Our science-based, holistic approach has shown that when you support resilient coastal communities, reduce water and other land-based pollution, and promote sustainable fisheries, reefs can adapt even in the face of global climate change. We are creating the better conditions that coral reefs need to survive.”</span>'
      },
      {
        dropdown: 'What are the carbon benefits of restoring coral reefs?',
        text: 'Coral reef ecosystems are one of the world’s carbon sinks. This means that they <span class="italic">absorb<span> and store more carbon than they emit, by storing it in their skeletons over their lifetimes. Around the world, coral reefs release 1.86 Megatons of carbon per year by means of calcification, but they store between 70 to 90 Megatons of carbon per year by CaCO3 accumulation in their skeleton.  Therefore, carbon net storage by coral reefs is between 68.14 and 88.14 Megatons of carbon per year considering both the released CO2 and the stocked CaCO3 involved in the formation and accumulation of coral skeleton.<br/><br/>'
          + 'Because corals are fantastic at storing carbon, it is essential that we restore coral reefs to full health in order to limit global warming to 1.5 degrees, in addition to rapidly reducing our carbon emissions.'
      },
      {
        dropdown: 'Over how many years does conservation work take place?',
        text: 'After it has been determined that a critical reef location meets a wide range of criteria to become a programme site, CORAL commit to being there and executing their conservation initiatives for a minimum of a decade (10 years+).<br/><br/>'
          + 'They never establish a new site without a clearly determined exit plan that indicates sustainability for a hand-off of the initiatives to the community and participating local organizations, if and when an exit of CORAL involvement occurs.'
      },
      {
        dropdown: 'What are the benefits of saving coral reefs?',
        text: '<span class="bold">Environmental Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Coral reefs restored to healthy state.<br/><br/>'
          + '•&nbsp;&nbsp;Corals more resilient to global warming.<br/><br/>'
          + '•&nbsp;&nbsp;Increased marine biodiversity.<br/><br/>'
          + '•&nbsp;&nbsp;Increased carbon storage in corals.<br/><br/>'
          + '•&nbsp;&nbsp;Well-stocked fisheries.<br/><br/>'
          + '<span class="bold">Social Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Better public awareness of the importance of corals.<br/><br/>'
          + '•&nbsp;&nbsp;Increased fish stocks.<br/><br/>'
          + '•&nbsp;&nbsp;Sustainable fishing practices implemented.<br/><br/>'
          + '•&nbsp;&nbsp;Communities better adapted to climate change.'
      },
    ],
    title: 'Coral Reef Protection in Hawaii',
    description: 'Despite being the most biodiverse marine ecosystems in the world on which many other marine life depend, corals are critically threatened. Funding this project will restore Hawaii’s ancient coral reefs through coastal restoration work on the island of Maui.',
    gallery: [
      '../../../assets/9-1.jpg',
      '../../../assets/8-2.jpg',
      '../../../assets/8-3.jpg',
      '../../../assets/8-4.jpg',
      '../../../assets/8-5.jpg',
      '../../../assets/8-6.jpg',
    ],
    summary: {
      quote: {
        type: 'quote',
        quote: '“While climate change threatens their future, science shows that corals can adapt and survive if we keep them healthy.”',
        author: 'CORAL',
      },
      ecoCoins: 2,
      impact: 'Life saving coral reef',
      images: [
        'sdg11.png',
        'sdg13.png',
        'sdg14.png',
        'sdg15.png',
      ],
      bullets: [
        'Since 1950, 50% of the world’s coral reefs have been lost, with 75% facing critical threat by 2050.',
        'Yet coral reefs are the most biodiverse marine ecosystems in the world.',
        'This project protects corals for the long term by addressing the drivers of coral reef loss.',
        'Key threats addresssed: pollution, overfishing, weak protection measures, unsustainable tourism practices.',
        'Key benefits: preservation of precious reefs, restored and sustainable fisheries, increased carbon sequestration, reduced pollution.',
        'Location and size: Wahikuli Watershed, Maui Island and Molokai Island, spanning 2,826 square kilometers of coral reefs.',
        'Timeframe: 10+ years action and monitoring.',
        'This project directly address the UNEP’s nature and climate crises.'
      ],
    },
    content: [
      {
        type: 'subtitle',
        data: 'Corals Under Threat'
      },
      {
        type: 'text',
        data: 'Coral reefs are one of the world’s richest sources of life and the most diverse marine ecosystem, despite only covering 0.1% of the earth’s surface. They are essential for supporting both tourism and fishing industries, providing 600 million people with income and food worldwide. As a result, the global value of coral reefs is estimated to be over $375 billion. They are also natural carbon sinks - essential for mitigating the effects of climate change - as they store 70-90 megatonnes of carbon per year by CaCO3 accumulation in their skeletons.'
      },
      {
        type: 'text',
        data: 'Yet our coral reefs are rapidly declining. 50% have been lost since 1950, and the combination of increasing global temperatures and overfishing is projected to place a further 75% under critical threat by 2050. It is imperative to restore degraded corals and protect existing corals to preserve these rich sources of life in our oceans.'
      },
      {
        type: 'image',
        image: '9-2.jpg',
        caption: 'Assessments being taken of one of the reefs in Maui, Hawaii.',
      },
      {
        type: 'subtitle',
        data: 'The Most Effective Way To Save Corals'
      },
      {
        type: 'text',
        data: 'Several charities simply ‘plant more corals’. But we believe that this is the wrong way to restore coral reef ecosystems. Planted corals are often unsuitable for the native ecosystem and quickly die off, and this approach doesn’t address the <span class="italic">underlying causes of coral reef degradation</span>, such as lack of protection and pollution. Instead, through our partnership with the <a target="_blank" href="https://www.coral.org/">Coral Reef Alliance</a> (CORAL), our coral reef protection projects take a <span class="italic">long-term, holistic</span> approach to coral reef restoration to restore and protect precious coral reefs.'
      },
      {
        type: 'text',
        data: 'Each project:<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Identifies threats to coral reefs.</span> This could be poor water quality, unsustainable fishing practices, ineffective or insufficient governance of marine protected areas or unsustainable tourism practices.<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Creates a plan.</span> CORAL then uses a science-backed approach to create a long-term plan to address all these problems simultaneously, over at least 10 years.<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Takes action to save the reefs.</span> This could include supporting resilient coastal communities, reducing water and other land-based pollution, coastal habitat restoration and promoting sustainable fisheries. monitor the results over several years.<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Ensures long-term management.</span> Each site is monitored for several years, and local partners are put in place to ensure lasting protection.<br/><br/>'
      },
      {
        type: 'image',
        image: '9-3.jpg',
        caption: 'Watershed restoration work in Maui, Hawaii.',
      },
      {
        type: 'subtitle',
        data: 'Coral Reef Protection In Maui, Hawaii.'
      },
      {
        type: 'text',
        data: '25% to 50% of Hawaii’s coral species are endemic (native to Hawaii), and almost a quarter of the native fish species depend on coral reefs for habitat and food, more than anywhere else in the world. The community are also heavily dependent on these reefs, worth $33.57 billion. Yet human-caused pollution and rising sea temperatures have significantly degraded these ecosystems, including major bleaching events on up to 80% of dominant corals. This project addresses these problems by restoring watersheds and coastal ecosystems back to ecological balance across the islands of Molokai and Maui.'
      },
      {
        type: 'google-maps',
      },
      {
        type: 'image',
        image: '9-4.png',
        caption: 'Map of the key areas targetted by this project.',
      },
      {
        type: 'text',
        data: 'On the islands of Maui and Molokai, a major issue is that heavy rains cause huge amounts of sediment and nutrients to run off into the ocean. This smothers some of Hawaii’s oldest reefs, reducing the corals’ access to sunlight and negatively impacts reef health. Similarly, the nutrient-rich runoff causes algal blooms which can overtake coral and promote coral disease.'
      },
      {
        type: 'text',
        data: 'This project is stopping this process by installing thousands of native plants along these coastlines to restore the natural function of an ahupua‘a (watershed) to filter stormwater and absorb nutrients, sediments and other chemicals. The result is cleaner water, more resilient corals, and in turn, increased biodiversity and improved fish populations for the local community.'
      },
      {
        type: 'text',
        data: 'Interested in supporting this project? You can protect the Maui coral coral reef for £0.30 per m2, either by signing up to our <a href="/choose-your-plan" class="turq-text bold underline pointer">individual subscription plan</a>, or with a <a href="/business" class="turq-text bold underline pointer">business subscription</a>.'
      },
      {
        type: 'image',
        image: '8-9.png',
        caption: '',
      },
    ],
  },
  {
    projectId: 4,
    url: 'rewilding-projects-uk',
    name: 'Wildflower Rewilding in the UK',
    thumbnail: '4-1.png',
    status: {
      name: "active",
      displayName: "Active"
    },
    //video: 'https://eden-blog-images.s3.eu-west-2.amazonaws.com/Laura-Interview.mp4',
    gallery: [
      '../../../assets/4-1.png',
      '../../../assets/4-6.jpeg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/rewilding-media/IMG_2559.JPG',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/rewilding-media/IMG_2567.JPG',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/rewilding-media/IMG_2484.JPG',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/rewilding-media/wildflowers.JPG',
    ],
    faqs: [
      {
        dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
        text: 'All our environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline black" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
          + 'This rewilding project has a direct, positive impact on the <span class="green bold">nature crisis</span>.<br/><br/>'
          + 'This is because rewilding directly increases biodiversity and nature cover, particularly of pollinator species.<br/><br/>'
          + 'In addition, pollinator species themselves are essential for the pollination of more wildflowers and the crops we rely on for our food production.'
      },
      {
        dropdown: 'What are B lines?',
        text: '‘B lines’ are wildflower corridors which have been specially drawn to target and link up stretches of natural grassland across the entirety of the UK.<br/><br/>'
          + 'These hundred-kilometre wide corridors are the sites where our rewilding projects take place.<br/><br/>'
          + '•&nbsp;&nbsp;Tree bumblebee (Bombus hypnorum)<br/><br/>'
          + '•&nbsp;&nbsp;Red-tailed bumblebee<br/><br/>'
          + '•&nbsp;&nbsp;White-tailed bumblebee<br/><br/>'
          + '•&nbsp;&nbsp;Common carder bee<br/><br/>'
          + '•&nbsp;&nbsp;Red mason bee<br/><br/>'
          + '•&nbsp;&nbsp;Tawny mining bee<br/><br/>'
          + '•&nbsp;&nbsp;Ashy mining bee<br/><br/>'
      },
      {
        dropdown: 'Which pollinator species benefit from this rewilding project?',
        text: 'Rewilding is great for the whole local ecosystem, from birds to plants to local animals. However, our rewilding project specifically targets dwindling pollinator species.<br/><br/>'
          + 'Butterflies, moths and flies are all pollinator species, but <span class="italic">bees</span> are the most critical. Our rewilding projects target a range of bees species, including:<br/><br/>'
          + 'Why B lines?<br/><br/>'
          + 'Many bees can only travel a few hundred metres from their home colony. These wildflower corridors allow bees to migrate through the countryside, feed off these wildflowers, and ultimately allow their populations to grow.'
      },
      {
        dropdown: 'Which wildflowers and grasses do you plant?',
        text: 'We plant a range of native grasses and wildflowers. These are specially chosen from native species suitable for the soil type, designed to thrive in the local habitat and boost local pollinator species.<br/><br/>'
          + "These include Crested Dog’s-tail, Greater Knapweed, Red Clover, Buttercup Meadow Crane's- bill, Sainfoin, Buttercups, Cowslips and Wild Carrot."
      },
      {
        dropdown: 'What is the process of turning a barren grassland site into a rich wildflower meadow?',
        text: '1. First, ideal sites for rewilding are chosen by looking for gaps along the B line network, or extending an existing wildflower corridor.<br/><br/>'
          + '2. Next, the chosen area of grassland is surveyed to assess which essential grasses and flowers are already thriving, and which are missing.<br/><br/>'
          + '3. The chosen grassland is then prepared for seeding by cutting the short and clearing away the grasses, without damaging the topsoil.<br/><br/>'
          + '4. Finally, the prepared area is seeded with a rich array of specially-chosen grasses and wildflowers. Once seeded, it’s simply a matter of waiting for the flowers to grow by themselves.'
      },
      {
        dropdown: 'How are the rewilding sites looked after?',
        text: 'All the wildflowers and plants are carefully chosen to fit with the local ecosystem, so they don’t require much maintenance.<br/><br/>'
          + 'However, for every rewilding site, the landowner is contractually obliged to trim back their meadows each year, to boost the growth potential.<br/><br/>'
          + 'This way, once the seeds are sown, the wildflower meadow gets richer and richer year on year.'
      },
      {
        dropdown: 'What are the benefits of rewilding',
        text: '<span class="bold">Environmental Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Conserves our native pollinators and target rare species.<br/><br/>'
          + '•&nbsp;&nbsp;Increases biodiversity more generally, from insects to birds to other grassland wildlife.<br/><br/>'
          + '•&nbsp;&nbsp;Helps wildlife respond to to climate change.<br/><br/>'
          + '<span class="bold">Social Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Increasing pollinator levels is crucial for pollinating crops across the UK.<br/><br/>'
          + '•&nbsp;&nbsp;Restores natural environments which the public can access, and brings people closer to nature.'
      },
    ],
    title: 'Best rewilding projects UK',
    description: 'Save the bees by cultivating wildflower habitats and rewilding the the UK.',
    summary: {
      quote: {
        type: 'quote',
        quote: `“Rewilding is not about abandoning civilization but about enhancing it. It is to 'love not man the less, but Nature more'.”`,
        author: '- George Monbiot'
      },
      ecoCoins: 1,
      impact: '6m2 of rewilded land',
      bullets: [
        'Although the UK is home to over 40,000 invertebrate species, our increasing urbanisation of wild spaces and the effects of climate change now threaten many of these species with extinction.',
        'Through the use of ‘B-lines’ as seen in the map, we are creating highways of wildflowers across the uk to enable cross-pollination, provide essential habitats and stimulate insect population growth.',
        'Buglife transform brownfield sites where human activity is suppressing the health of ecosystems, and turning them into important sites of natural restoration, by returning the land to nature.',
        'This brings green spaces into the hearts of our urban centres which not only is a lifeline for our declining insect populations, but boosts air quality, mental health and productivity in the concrete jungles of modern cities.',
        'Rewilding reverses the harmful effects of human activity, such as noisy and disrupting  transportation links, human pollution from cities and agriculture and land clearing for industrial activity.',
        'The hectares of newly rewilded wildflower meadows which we restore become a haven for the UK’s declining pollinator population; insects that we rely on to pollinate thereby grow domestically sourced food within this country.',
        'This project directly addresses the UNEP’s nature crisis.',
      ],
      images: [
        'sdg11.png',
        'sdg15.png',
        'sdg12.png',
      ],
    },
    content: [
      {
        type: 'subtitle',
        data: 'Wildflower Rewilding Project:'
      },
      {
        type: 'image',
        image: '4-2.png',
        caption: 'One of Eden Greenspace’s rewilded wildflower meadows in North Cornwall and a Monarch butterfly.',
      },
      {
        type: 'text',
        data: 'Through our partnership with <a target="_blank" href="https://www.buglife.org.uk/">Buglife</a>, you can help conserve British pollinators, allow wildlife to survive through climate change, and benefit the farming sector by increasing the number of invertebrates in the UK.'
      },
      {
        type: 'text',
        data: 'Our rewilding project has a direct, positive impact on the <span class="bold green">nature crisis</span>.'
      },
      {
        type: 'text',
        data: 'This is because rewilding directly increases biodiversity and nature cover, particularly of pollinator species.'
      },
      {
        type: 'text',
        data: 'In addition, pollinator species themselves are essential for the pollination of more wildflowers and the crops we rely on for our food production.'
      },
      {
        type: 'subtitle',
        data: 'UK Rewilded B-Lines'
      },
      {
        type: 'google-maps',
      },
      {
        type: 'image',
        image: '4-3.jpeg',
        caption: 'Map showing the ‘B-Line’ highways across the UK.',
      },
      {
        type: 'text',
        data: '<a href="https://www.buglife.org.uk/our-work/b-lines/b-lines-guidance/downloadable-b-lines-maps/" target="_blank"> https://www.buglife.org.uk/our-work/b-lines/b-lines-guidance/downloadable-b-lines-maps/</a>'
      },
      {
        type: 'text',
        data: '‘B-lines’ are an ingenious solution to tackle the decline of bee populations.'
      },
      {
        type: 'text',
        data: 'Acting as a series of insect highways that run across the UK, ‘B-lines’ connect green areas by creating a network of habitats which invertebrates can travel between.'
      },
      {
        type: 'text',
        data: 'We are aiming to restore 150,000 hectares of wildflower-rich habitats, despite 97% of British flower habitats being lost since 1930.'
      },
      {
        type: 'text',
        data: 'Our process of B-line rewilding proceeds as follows:'
      },
      {
        type: 'text',
        data: '1. First, ideal sites for rewilding are chosen by looking for gaps along the B line network, or extending an existing wildflower corridor.'
      },
      {
        type: 'text',
        data: '2. Next, the chosen area of grassland is surveyed to assess which essential grasses and flowers are already thriving, and which are missing.'
      },
      {
        type: 'text',
        data: '3. The chosen grassland is then prepared for seeding by cutting the short and clearing away the grasses, without damaging the topsoil.'
      },
      {
        type: 'text',
        data: '4. The prepared area is seeded with a rich array of specially-chosen grasses and wildflowers. Once seeded, it’s simply a matter of waiting for the flowers to grow by themselves.'
      },
      {
        type: 'text',
        data: '5. Finally, the meadows are monitored and stewarded, but as the wildflowers and plants are carefully chosen to fit with the local ecosystem, so they don’t require much maintenance. However, for every rewilding site, the landowner is contractually obliged to trim back their meadows each year, to boost the growth potential. This way, once the seeds are sown, the wildflower meadow gets richer and richer year on year.'
      },
      {
        type: 'image',
        image: '4-4.jpg',
        caption: 'One of Eden Greenspaces stewardship teams on location at our Pentireglaze rewilding site in North Cornwall.',
      },
      {
        type: 'image',
        image: '4-5.jpg',
        caption: 'One of Eden Greenspaces stewardship teams on location at our Pentireglaze rewilding site in North Cornwall.',
      },
      {
        type: 'image',
        image: '4-6.jpeg',
        caption: 'One of Eden Greenspaces stewardship teams on location at our Pentireglaze rewilding site in North Cornwall.',
      },
      {
        type: 'text',
        data: "Bees pollinate a third of the world's crops, and yet a third of British bees and hoverflies are in decline due to climate change, habitat loss, and pesticide use."
      },
      {
        type: 'text',
        data: 'As well as being crucial for crop pollination, these pollinators are an important part of the food chain, help sustain wildlife habitats and food sources, and play one of the most important roles in our planet’s ecosystem.'
      },
      {
        type: 'text',
        data: 'As well as being crucial for crop pollination, these pollinators are an important part of the food chain, help sustain wildlife habitats and food sources, and play one of the most important roles in our planet’s ecosystem.'
      },
      {
        type: 'text',
        data: "These include Crested Dog’s-tail, Greater Knapweed, Red Clover, Buttercup Meadow Crane's- bill, Sainfoin, Buttercups, Cowslips and Wild Carrot."
      },
      {
        type: 'image',
        image: '4-7.png',
        caption: 'Holly blue butterfly.',
      },
      {
        type: 'text',
        data: "Our rewilding partnership project has played a part in saving invertebrates from extinction, restored more than 230 hectares of bog habitat, banned multiple dangerous pesticides, and now, with the help of your contribution, they are mapping B-Line highways all across the UK. Our rewliding has a huge range of other benefits aswell:"
      },
      {
        type: 'text',
        data: '<span class="bold">Environmental Impact;</span>'
      },
      {
        type: 'text',
        data: "•&nbsp;&nbsp;Conserves our native pollinators and target rare species."
      },
      {
        type: 'text',
        data: "•&nbsp;&nbsp;Increases biodiversity more generally, from insects to birds to other grassland wildlife."
      },
      {
        type: 'text',
        data: "•&nbsp;&nbsp;Helps wildlife respond to to climate change."
      },
      {
        type: 'text',
        data: '<span class="bold">Social Impact:</span>'
      },
      {
        type: 'text',
        data: "•&nbsp;&nbsp;Increasing pollinator levels is crucial for pollinating crops across the UK."
      },
      {
        type: 'text',
        data: "•&nbsp;&nbsp;Restores natural environments which the public can access, and brings people closer to nature."
      },
      {
        type: 'subtitle',
        data: 'Help us to restore our pollinator populations by taking part in this project!'
      },
    ]
  },
  {
    projectId: 5,
    url: 'marine-plastic-removal-around-the-uk',
    name: 'Ocean plastic removal around the UK',
    thumbnail: '5-2.png',
    gallery: [
      '../../../assets/5-2.png',
      '../../../assets/5-1.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/plastics-media/Other-Marine-Plastic-Cleanup-media/IMG_5967.JPG',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/plastics-media/Other-Marine-Plastic-Cleanup-media/20220804_170512.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/plastics-media/Other-Marine-Plastic-Cleanup-media/IMG_5961.JPG',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/plastics-media/Other-Marine-Plastic-Cleanup-media/IMG_5975.JPG',
      '../../../assets/5-8.jpg',
      '../../../assets/5-9.jpg',
    ],
    status: {
      name: "active",
      displayName: "Active"
    },
    //video: 'https://eden-blog-images.s3.eu-west-2.amazonaws.com/Steve-Interview.mp4',
    faqs: [
      {
        dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
        text: 'All our environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline black" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
          + 'Our plastic removal project has a direct, positive impact on the <span class="blue bold">pollution crisis</span>.<br/><br/>'
          + 'Plastic waste is harmful both on land and sea, and this project helps to address this by clearing coastal areas of marine plastic, <span class="italic">before</span> it has a chance to break down into harmful microplastics, which are especially harmful to both animals and humans.'
      },
      {
        dropdown: 'What kind of plastic is collected?',
        text: 'All kinds. The most common by weight is old fishing nets, but the team down in Cornwall will pick up anything they find.<br/><br/>'
          + 'They have previously found everything from plastic bottles and bags to plastic packaging, fire hose pipes, tyres and plastic packaging.'
      },
      {
        dropdown: 'Where is the plastic collected?',
        text: 'This project specifically targets inaccessible coastal regions around the South-West of England, although the team also do trips across the UK and Brittany.<br/><br/>'
          + 'This project specifically targets inaccessible coastal regions around the South-West of England, although the team also do trips across the UK and Brittany.<br/><br/>'
          + 'Then, the kayaks take the plastic back to the plastic mothership, The Annette, which can collect tonnes of plastic at a time.'
      },
      {
        dropdown: 'What happens to the plastic once it is collected?',
        text: 'Once the plastic is removed, it is boarded on to the wind-powered plastic mothership, called <span class="italic">The Annette</span>.<br/><br/>'
          + 'From there, the team sail all the way up to Exeter, where the plastic is recycled into plastic kayaks, <span class="bold italic">which are then used to collect more plastic!</span>'
      },
      {
        dropdown: 'What are the benefits of marine plastic clean-up?',
        text: '<span class="bold">Environmental Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Removes plastic from rivers and coastlines before it reaches the ocean.<br/><br/>'
          + '•&nbsp;&nbsp;Prevents plastic harming marine wildlife such as turtles, whales, dophins, birds and other fish.<br/><br/>'
          + '•&nbsp;&nbsp;Prevents plastics breaking down into micro-plastics - harmful for both fish and humans.<br/><br/>'
          + '•&nbsp;&nbsp;By protecting marine life, it stabilises the climate and thereby reduces the impact of climate change.<br/><br/>'
          + '<span class="bold">Social Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Allows people to enjoy a cleaner and healthier natural environment.<br/><br/>'
          + '•&nbsp;&nbsp;Draws attention to the real social change needed to eliminate plastic from our daily lives.'
      },
    ],
    title: 'Marine plastic removal around the UK',
    description: 'Learn more about Eden Greenspace’s marine plastic removal project in Cornwall, and how you can account for your plastic footprint.',
    summary: {
      quote: {
        type: 'quote',
        quote: '“Water and air, the two essential fluids on which all life depends, have become global garbage cans”',
        author: '- Jacques Yves Cousteau',
      },
      ecoCoins: 1,
      impact: '1kg marine plastic removed',
      images: [
        'sdg3.png',
        'sdg6.png',
        'sdg12.png',
        'sdg14.png',
      ],
      bullets: [
        'Almost 13 million tons of plastic are dumped into the ocean every year, along with 4 billion microscopic plastic fibres covering every square kilometer of seabed.',
        'Plastic is more or less indestructible, and simply becomes smaller and smaller until eventually it is considered a ‘microplastic’, a fragment of plastic under 5mm in length that can sometimes only be seen under a microscope.',
        'It is known that we are ingesting toxic microplastics every day through the seafood that we eat, which has eaten the microplastics in the ocean.',
        'COS sails the Annette, a 100 year old schooner around the coasts of Cornwall cleaning the oceans of these harmful plastics.',
        'Under sail, they are able to access areas on marine protected beach such as certain areas of the Scilly Islands, where motor vessels are banned from. Yet plastic still washes up on these protected area shores, so the unique work of COS is vital.',
        'The plastic is then sailed to, deposited and recycled into new products up the coast in Exeter, who transform the waste plastic into products such as kayaks.',
        'This project directly address the UNEP’s pollution crisis.',
      ]
    },
    content: [
      {
        type: 'subtitle',
        data: 'Marine plastic clean-up'
      },
      {
        type: 'text',
        data: 'Through our partnership with <a href="https://cleanoceansailing.com/">Clean Ocean Sailing</a>, a non-profit organisation from Cornwall, you can help reduce the amount of plastic from our seas, protect marine life and prevent the dangerous effects of plastic from reaching our shores.'
      },
      {
        type: 'text',
        data: 'For the COS team sail their boat The Annette up and down the British Isles collecting plastic waste from remote beaches and saving wildlife along the way. They also make longer trips further into Europe in order to reach more ground.'
      },
      {
        type: 'text',
        data: 'Additionally, they run recycling plastic workshops and educational programs so that people can learn more about the dangers of plastic in our oceans.'
      },
      {
        type: 'image',
        image: '5-2.jpeg',
        caption: 'Volunteers at a beach clean up during the ‘10 beaches in 10 days challenge’ of February 2020.',
      },
      {
        type: 'google-maps',
      },
      {
        type: 'image',
        image: '5-3.png',
        caption: 'Sea areas which we are primarily targeting - the north and south coasts of Cornwall and between the mainland and the isles of scilly.',
      },
      {
        type: 'image',
        image: '5-4.png',
        caption: 'Zoomed in view of the Isles of Scilly, supposedly a ‘UK government Marine Protected Area (MPA)’, rich in wildlife yet directly exposed to plastic washing up on its shores by Atlantic currents.',
      },
      {
        type: 'image',
        image: '5-5.png',
        caption: 'Clean Ocean Sailing are based on the Helston river in Cornwall, UK',
      },
      {
        type: 'text',
        data: 'Clean Ocean Sailing are based at the top of the Helston river in a village called Gweek, Cornwall, however their work takes them across the British Isles.'
      },
      {
        type: 'text',
        data: 'In 2019, they travelled to the Isles of Scilly where they collected masses of plastic waste, including shoes, bottles, fishing equipment, but also witnessed the destructive power of plastic when they found a dolphin killed by plastic waste.'
      },
      {
        type: 'image',
        image: '5-6.png',
        caption: 'Sea bird sitting amongst a pile of plastic waste.',
      },
      {
        type: 'text',
        data: 'The death toll imposed upon wildlife because of plastic is sobering. Each year, 1 million seabirds die from plastic. '
      },
      {
        type: 'text',
        data: 'They are especially susceptible to plastic ingestion - in fact it is estimated that 90% of seabirds have ingested plastic. '
      },
      {
        type: 'text',
        data: 'They are especially susceptible to plastic ingestion - in fact it is estimated that 90% of seabirds have ingested plastic. '
      },
      {
        type: 'text',
        data: 'Our plastic removal project has a direct, positive impact on the <span class="bold blue">pollution crisis</span>.'
      },
      {
        type: 'text',
        data: 'Plastic waste is harmful both on land and sea, and this project helps to address this by clearing coastal areas of marine plastic, <span class="italic">before</span> it has a chance to break down into harmful microplastics, which are especially harmful to both animals and humans.'
      },
      {
        type: 'image',
        image: '5-7.jpeg',
        caption: 'Some of the bags of plastic waste found during the 2019 mission in the Isles of Scilly - captain Steve at the helm!',
      },
      {
        type: 'text',
        data: 'Thanks to the work provided by our plastic cleanup partners, tonnes of plastic has been removed from our oceans, saving the lives of many marine animals, and reducing the risks of plastic pollution around Europe.'
      },
      {
        type: 'text',
        data: 'Once the plastic is removed, it is boarded on to the sail-powered plastic mothership, called <span class="italic">The Annette</span>.'
      },
      {
        type: 'text',
        data: 'From there, the team sail all the way up to Exeter, where the plastic is recycled into plastic kayaks as seen below, <span class="bold italic">which are then used to collect more plastic!</span>'
      },
      {
        type: 'image',
        image: '5-8.jpg',
        caption: '',
      },
      {
        type: 'image',
        image: '5-9.jpg',
        caption: '',
      },
    ]
  },
  {
    projectId: 6,
    url: 'solar-lighting',
    name: 'Solar Lighting in Zambia and Malawi',
    thumbnail: '6-1.png',
    status: {
      name: "active",
      displayName: "Active"
    },
    faqs: [
      {
        dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
        text: 'All our environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline black" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
          + 'Solar lights provide a direct, positive impact on the <span class="red bold">climate crisis</span>.<br/><br/>'
          + 'Solar lights avoided huge amounts of CO2 emissions by replacing the existing kerosene lamps which rural families in southern Africa rely on.<br/><br/>'
          + 'More than this, they make clean, green energy accessible for impoverished local families. This teaches locals about the importance of clean energy, and embeds sustainable practices into the community.'
      },
      {
        dropdown: 'Why are solar lights so much better than kerosene lamps?',
        text: 'The local families in Malawi and Zambia which this project targets currently use kerosene lamps to light their homes.<br/><br/>'
          + 'Kerosene lamps are not only bad for the environment, but emit toxic fumes and are highly flammable. Kerosene is also hugely expensive for local families, costing them the equivalent of £130 in energy costs.<br/><br/>'
          + 'Solar lights avoid all these problems. As they are powered by the sun’s energy, they are free to use.<br/><br/>'
          + 'Unlike kerosene lamps, solar lights are completely safe, and provide clean, green energy to communities who would otherwise not be able to afford it.<br/><br/>'
          + 'This allows them to safely light their homes, extend their working day and provide more study time for their children.',
      },
      {
        dropdown: 'Who receives the solar lights?',
        text: 'This project specifically targets the poorest families in Malawi and Zambia, who live in remote, off-grid locations and do not have access to mains electricity.<br/><br/>'
          + 'Solar lights are literally life-saving for these families. Because they do not have access to an electric grid, they need a portable light source.<br/><br/>'
          + 'Solar lights provide this - except without the huge cost and dangers which come with the normal kerosene lamps found in these areas.',
      },
      {
        dropdown: 'How much CO2 emissions are avoided replacing a kerosene lamp with a solar light?',
        text: 'Over their 10 year lifespan, each solar light is estimated to avoid the equivalent of 1.1 tonnes of CO2 emissions.<br/><br/>'
          + 'That’s about the equivalent to the emissions of a return flight from London to Dubai.',
      },
      {
        dropdown: 'How long do solar lights last and how are they maintained?',
        text: 'Solar lights can light up a family’s home for at least 10 years.<br/><br/>'
          + 'But if they break sooner, part of the cost of the solar light goes towards setting up specialist repair shops.<br/><br/>'
          + 'Local people are trained so that they have the necessary skills for technical repairs, and can access pieces of kit which are ordinarily hard to find in rural Africa<br/><br/>'
          + 'All of this contributes to creating long-term, sustainable energy practices in Southern Africa.'
      },
      {
        dropdown: 'What are the benefits of solar lighting?',
        text: '<span class="bold">Environmental Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;1.1 tonnes of CO2 emissions avoided per light.<br/><br/>'
          + '•&nbsp;&nbsp;Reduces toxic fumes emitted by kerosene lamps.<br/><br/>'
          + '•&nbsp;&nbsp;Embeds sustainable practices for the future in some of the poorest villages in the world.<br/><br/>'
          + '<span class="bold">Social Impact:</span><br/><br/>'
          + 'Solar lights provide families with:<br/><br/>'
          + '•&nbsp;&nbsp;Free, sustainable energy.<br/><br/>'
          + '•&nbsp;&nbsp;Greater safety in the evenings.<br/><br/>'
          + '•&nbsp;&nbsp;No toxic fumes inside the home.<br/><br/>'
          + '•&nbsp;&nbsp;An extended working day.<br/><br/>'
          + '•&nbsp;&nbsp;Helps bring families above the poverty line.<br/><br/>'
          + '•&nbsp;&nbsp;Provides the average child with over 1000 hours of extra study time during the evenings.<br/><br/>'
          + '•&nbsp;&nbsp;Saves a family the equivalent of £130 in energy costs.'
      },
    ],
    title: 'Solar Energy used as lighting to replace toxic kerosene lamps, reducing CO2 and improving air quality.',
    description: 'Learn more about Eden Greenspace’s solar light distribution initiative in Malawi and Zambia, and how can support a solar energy lighting transition, reduce CO2 and improve air quality for some of the world’s poorest families',
    gallery: [
      '../../../assets/6-1.png',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/solar-lighting/Photos-and-Videos/LWC_Zambia19_day2_0199.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/solar-lighting/Photos-and-Videos/Screenshot(196).png',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/solar-lighting/Photos-and-Videos/Screenshot(203).png',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/solar-lighting/Photos-and-Videos/Solar-Lights-photo.jpg',
    ],
    summary: {
      quote: {
        type: 'quote',
        quote: '“We believe no home, school or health clinic should be left in the dark.”',
        author: '',
      },
      ecoCoins: 5,
      impact: 'Life saving light for 2 people',
      images: [
        'sdg3.png',
        'sdg13.png',
        'sdg7.png',
        'sdg8.png',
      ],
      bullets: [
        '590 million people without access to electricity in sub-Saharan Africa.',
        'The alternative to electric lighting is to use a conventional open flame.',
        'Yet, candles and kerosene lamps are expensive, toxic and dangerous',
        'Without reliable and sustainable electric lighting economies cannot develop as work cannot continue effectively after dark, with life being pegged to the cycle of the sun.',
        'Small modular solar lights can bridge the gap between the slow roll-out of state electrification to provide a safe and clean light source for families in need. ',
        'Over their 10 year lifespan, each solar light is estimated to avoid the equivalent of 1.1 tonnes of CO2 emissions. That’s about the equivalent to the emissions of a return flight from London to Dubai.',
        'This project directly tackles the UNEP’s Climate crisis by offering a clean alternative to high CO2 emitting kerosene light sources.',
      ],
    },
    content: [
      {
        type: 'text',
        data: 'Through our partnership on the ground with <a href="https://solar-aid.org/" target="_blank">SolarAid</a>, you can provide renewable lighting for struggling families in place of fossil fuels.'
      },
      {
        type: 'text',
        data: '•&nbsp;&nbsp;Kerosene lamps emit 370kg CO2 each year, Solar Lights run on renewable energy from the sun, and since the charity was first founded in 2006, SolarAid have distributed 2 million of them.'
      },
      {
        type: 'text',
        data: '•&nbsp;&nbsp;As a result, 11 million people have been given access to safe lighting, preventing 2 million tonnes of carbon from being released into the atmosphere.'
      },
      {
        type: 'text',
        data: '•&nbsp;&nbsp;This project directly addresses the UNEP’s climate crisis.'
      },
      {
        type: 'quote',
        quote: '"A lot of things have changed [thanks to the solar light]. The house is brighter and the children are happy."',
        author: '- WEAKNESS MWENELUPEMBE, MALAWI'
      },
      {
        type: 'google-maps'
      },
      {
        type: 'image',
        image: '6-2.png',
        caption: 'Malawi have vast areas of unlit countryside, who need off-grid solar solutions to light up their lives.  (Map from ARCGIS)',
      },
      {
        type: 'image',
        image: '6-3.png',
        caption: 'Malawi have vast areas of unlit countryside, who need off-grid solar solutions to light up their lives.  (Map from ARCGIS)',
      },
      {
        type: 'subtitle',
        data: 'Now for a little background information on some of the amazing work SolarAid do.'
      },
      {
        type: 'text',
        data: 'We work with our project partner in some of the most remote and disadvantaged towns, providing lights for schools, homes and workplaces, including training and recruiting key female workers who would otherwise be unemployed.'
      },
      {
        type: 'text',
        data: '18 of the 20 poorest countries in the world are found in sub-Saharan Africa, with millions of people earning less than £5 a day.'
      },
      {
        type: 'image',
        image: '6-4.png',
        caption: 'A young man using a solar light to help him study',
      },
      {
        type: 'text',
        data: 'Without a light source, families are forced to cut their days short as evening approaches, and are no longer able to work, study or care for loved ones'
      },
      {
        type: 'text',
        data: 'Additionally, unclean and harmful light sources not only pollute the environment but can badly affect their health long term.'
      },
      {
        type: 'text',
        data: 'Kerosene lamps produce thick and toxic smoke, causing 4 million people per year to die prematurely from household air pollution.'
      },
      {
        type: 'text',
        data: 'Solar lights provide a direct, positive impact on the <span class="bold red">UNEP’s climate crisis</span>.'
      },
      {
        type: 'text',
        data: 'The provision of these communities with Solar lights avoid huge amounts of CO2 emissions by replacing the existing kerosene lamps which rural families in southern Africa rely on.'
      },
      {
        type: 'text',
        data: 'More than this, they make clean, green energy accessible for impoverished local families. This teaches locals about the importance of clean energy, and embeds sustainable practices into the community.'
      },
      {
        type: 'text',
        data: 'Each Solar Light has a 10-year lifespan, replacing the need for toxic kerosene lamps'
      },
      {
        type: 'text',
        data: 'But if they break sooner, part of the cost of the solar light goes towards setting up specialist repair shops.'
      },
      {
        type: 'text',
        data: 'Local people are trained so that they have the necessary skills for technical repairs, and can access pieces of kit which are ordinarily hard to find in rural Africa.'
      },
      {
        type: 'text',
        data: 'All of this contributes to creating long-term, sustainable energy practices in Southern Africa.'
      },
      {
        type: 'image',
        image: '6-5.png',
        caption: 'SolarAid volunteers distributing Solar Lights',
      },
      {
        type: 'text',
        data: "Help us tackle the climate crisis while supporting some of the world's poorest families by choosing this project."
      },
    ],
  },
  {
    projectId: 7,
    url: 'clean-cookstoves',
    name: 'Clean Cookstoves in Uganda',
    thumbnail: '7-1.jpg',
    status: {
      name: "active",
      displayName: "Active"
    },
    faqs: [
      {
        dropdown: 'How much does it cost to provide 1 clean cookstove?',
        text: 'You can provide a family with a clean cookstove for £11, either by signing up to our <a class="bold turq-text pointer underline" href="/choose-your-plan">individual subscription plan</a>, or with a <a class="bold turq-text pointer underline" href="/business">business subscription</a>.<br/><br/>'
          + 'We do not currently sell cookstove bracelets, but you can purchase our tree-planting bracelets <a class="bold turq-text pointer underline" href="/products/eden-bracelet">here.</a>'
      },
      {
        dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
        text: 'All our environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline black" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
          + 'Our cookstove project simultaneously addresses both the climate crisis and the pollution crisis, by replacing toxic open-fire cooking with clean, efficient cookstoves.<br/><br/>'
          + 'These stoves address the climate crisis, in that they reduce the amount of wood being burnt by 70% compared to an open fire, avoiding 5 tonnes of carbon dioxide emissions over their lifetimes.<br/><br/>'
          + 'The stoves address the pollution crisis, in that they do not release toxic black carbon particulates, which are linked to millions of deaths annually.<br/><br/>'
          + 'With over 2 billion people using inefficient cooking methods globally, upgrading these methods is good for both the climate and the air we breathe.'
      },
      {
        dropdown: 'Do you or your project partners use clean cookstoves to produce carbon offsets?',
        text: 'Both Eden Greenspace and The Cookstove Project do not condone, create or sell carbon offsets as part of their charitable activities.'
      },
      {
        dropdown: 'Do you only work in Uganda?',
        text: 'Currently, yes, but we are looking to expand this project to cover Nepal in the near future.'
      },
      {
        dropdown: 'How are the CO2 reductions calculated?',
        text: 'CO2 reductions data is taken from a report by the University of Makerere in Uganda, which tested the carbon emissions of several stoves to measure their efficiency compared to open fire cooking methods.'
      },
      {
        dropdown: 'Why don’t you build the cookstoves yourselves?',
        text: 'The family ownership of each stove is the most effective way to provide cookstoves, as it empowers, rather than imposes, local villagers to transition towards safer and more sustainable ways of cooking. The sustainability is vital to embedding sustainable practices into communities, and ensuring the long term reduction of carbon emissions.'
      },
      {
        dropdown: 'What are the benefits of clean cookstoves?',
        text: '<span class="bold">Environmental Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;2.9 tonnes CO2 emissions avoided per cookstove, per year<br/><br/>'
          + '•&nbsp;&nbsp;Reduces toxic black carbon emissions produced by incomplete combustion in open fires<br/><br/>'
          + '•&nbsp;&nbsp;Reduces the amount of wood required for cooking by almost 70%, thereby reducing deforestation<br/><br/>'
          + '<span class="bold">Social Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Reduces rates of pneumonia, especially in children<br/><br/>'
          + '•&nbsp;&nbsp;Reduces the time spent collecting firewood and increases the time spent studying and in work'
          + '•&nbsp;&nbsp;Reduces the number of burns from open fires<br/><br/>'
          + '•&nbsp;&nbsp;Increases the number of families boiling water before drinking<br/><br/>'
          + '•&nbsp;&nbsp;Reduces preventable deaths caused by air pollution<br/><br/>'
      },
    ],
    title: 'Clean Cookstoves in Uganda.',
    description: 'Globally, over 2 billion people use toxic open-fire cooking methods that claims the lives of approximately 3.8 million people each year. Clean cookstoves are the solution. By using 66% less wood, clean cookstoves reduce deforestation and air pollution at the same time.',
    gallery: [
      '../../../assets/7-1.jpg',
      '../../../assets/7-2.jpg',
      '../../../assets/7-3.jpg',
      '../../../assets/7-11.jpg',
      '../../../assets/7-12.jpg',
    ],
    summary: {
      quote: {
        type: 'quote',
        quote: '“Worldwide emissions from traditional three stone fires contribute about 5% of annual greenhouse gas emissions…equivalent to the annual emissions generated by the aviation industry”',
        author: 'The Cookstove Project',
      },
      ecoCoins: 5,
      impact: 'Life saving light for 2 people',
      images: [
        'sdg3.png',
        'sdg13.png',
        'sdg7.png',
        'sdg15.png',
      ],
      bullets: [
        'An estimated 3.8 million Ugandan households use a traditional three-stone fire to cook their meals.',
        'Globally, over 2 billion people use this toxic and inefficient cooking method that claims the lives of approximately 3.8 million people each year.',
        'Open fire cooking results in 25% of global black carbon emissions.',
        'Black carbon is the second largest driver of climate change after carbon dioxide.',
        'This project provides an alternative to open fires: clean cookstoves.',
        'Clean cookstoves use 66% less wood than an open fires, emitting almost no black carbon.',
        'Each cookstove reduces carbon dioxide emissions by 5 tonnes per cookstove over a 5-year lifetime.',
        'By using less wood, clean cookstoves simulatenously reduce deforestation, air pollution and carbon dioxide emissions.',
        'This project directly addresses the UNEP’s climate and pollution crises.'
      ],
    },
    content: [
      {
        type: 'text',
        data: '<br/><br/>'
      },
      {
        type: 'text',
        data: 'Through our partnership with world-leading charity <a href="https://cookstoveproject.org/" target="_blank">The Cookstove Project</a>, you can provide families with greener and safer cookstoves, reducing toxic air pollutants and CO2 emissions caused by open-fires.'
      },
      {
        type: 'subtitle',
        data: 'The Problem: Open - Fire Cooking'
      },
      {
        type: 'text',
        data: '<span class="bold">Deforestation:</span>'
      },
      {
        type: 'text',
        data: 'Globally, over 2 billion people use woodfuel for cooking with open fires, and this practice has become a huge driver of deforestation. According to the United Nations Food and Agriculture Organization, since 1990, Uganda has lost 29 percent of its forests and “almost 90 percent of all wood harvested in Africa is used for fuel.”'
      },
      {
        type: 'text',
        data: '<span class="bold">Black Carbon, air pollution and global warming.</span>'
      },
      {
        type: 'text',
        data: 'Further, inefficient cooking methods release sooty particulate matter called ‘black carbon’, which is a major cause of both carbon emissions and air pollution.'
      },
      {
        type: 'text',
        data: 'The UNEP estimates that the use of inefficient cooking methods like open fires cause 25% of black carbon emissions, and are “second only to CO2 in their warming impact on the climate.” The Environmental Protection Agency estimates that “the average open fire produces nearly as much carbon dioxide as the average motor vehicle.” Inefficient cooking methods are therefore a major source of global carbon emissions.'
      },
      {
        type: 'text',
        data: 'Open fire cooking is a leading cause of death globally. Over 3.8 million people, mostly women and children, die each year as a result of cooking indoors over an open fire. Worldwide, pneumonia is the leading cause of death in children under five. At least half of these deaths are attributed to the soot they inhale from open fire cooking in their homes. The World Health Organization has determined that regular exposure to smoke from these fires has the same impact as smoking 40 cigarettes per day.'
      },
      {
        type: 'image',
        image: '7-4.png',
        caption: '',
      },
      {
        type: 'subtitle',
        data: 'The Solution: Clean Cookstoves'
      },
      {
        type: 'text',
        data: 'By providing families with clean and efficient cookstoves, this project reduces both air pollution and eliminates preventable deaths caused by open-fire cooking.'
      },
      {
        type: 'text',
        data: 'Studies have shown that our clean cookstove uses 66% less wood than an open fire, and each cookstove reduces carbon dioxide emissions by 2.9 tonnes each year. This amount is equivalent to the emissions of a flight from London to Syndey. Efficient clean cookstoves will slow down deforestation and carbon dioxide emissions.'
      },
      {
        type: 'text',
        data: 'The resulting benefits for families are dramatic. Using clean cookstoves, homes are no longer filled with smoke and soot, families breathe cleaner air, and the increased efficiency of the stoves means less time spent gathering fuel and reduced carbon emissions. Recent results from field studies of cookstove usage indicate: 69% decrease in instances of pneumonia, 83% reduction in severe burns caused by cooking, 7.6 hours less hours per week spent collecting firewood, and an eight-fold increase in number of families that can now boil their water before drinking it.'
      },
      {
        type: 'text',
        data: 'In short, by reducing toxic black carbon emissions and keeping more trees in the ground, our clean cookstoves project addresses <a class="bold turq-text pointer underline" href="/about/overview">both the crises of air pollution and climate change</a>.'
      },
      {
        type: 'image',
        image: '7-5.jpg',
        caption: '',
      },
      {
        type: 'subtitle',
        data: 'How it works on the ground'
      },
      {
        type: 'text',
        data: 'Since 2013, <a href="https://cookstoveproject.org/" target="_blank">The Cookstove Project</a> have provided 30,000 cookstoves in Uganda and Nepal, improving the lives of more than 150,000 people.'
      },
      {
        type: 'text',
        data: 'Using locally sourced materials, they help families to gain access to safe, efficient, clean cookstoves. Clean cookstoves contain and direct the fire to produce cleaner, hotter energy with no smoke emitted in the home. The Cookstove Project supports and trains families to build and maintain their stoves to enable sustainability. By participating in the construction of their stove, each family is equipped with the knowledge and skills to repair and preserve their stove.'
      },
      {
        type: 'text',
        data: 'The family ownership of each stove is the most effective way to provide cookstoves, as it empowers, rather than imposes local villagers to transition towards safer and more sustainable ways of cooking. The sustainability is vital to ensuring the long term reduction of carbon emissions. For example, one of our cookstoves will reduce carbon emissions 5 tonnes in just a 5 year period.'
      },
      {
        type: 'subtitle',
        data: 'Sites and Mapping'
      },
      {
        type: 'image',
        image: '7-7.png',
        caption: 'Map depicts the land degredation across Uganda between 2010 (date of the last assessment) to 2020.',
      },
      {
        type: 'image-and-text',
        text: 'As defined by the WHO: “Land degradation is caused by multiple forces, including extreme weather conditions, particularly drought.It is also caused by human activities that pollute or degrade the quality of soils and land utility.It negatively affects food production, livelihoods, and the production and provision of other ecosystem goods and services.Desertification is a form of land degradation by which fertile land becomes desert.',
        image: '7-13.png'
      },
      {
        type: 'text',
        data: 'The central north zone of the country land degradation is comparatively lower than around the capital and border provinces. Partly due to improved local stewardship in the north, alongside national parks and NGO activity, this land has been nurtured. However in the rest of the country, particularly in the south around the capital Kampala and on the borders, land degradation is unfortunately very high.'
      },
      {
        type: 'image',
        image: '7-9.png',
        caption: 'Depicts historic deforestation across the country (red) and our project sites.',
      },
      {
        type: 'text',
        data: 'Interested in supporting this project? You can provide a family with a clean cookstove for £11, either by signing up to our <a class="bold turq-text pointer underline" href="/choose-your-plan">individual subscription plan</a>, or with a <a class="bold turq-text pointer underline" href="/business">business subscription</a>.'
      },
      {
        type: 'image',
        image: '7-6.webp',
        caption: '',
      },
    ],
  },
  {
    projectId: 3,
    url: 'tree-planting-in-kenya',
    name: 'Tree Planting in Kenya',
    thumbnail: '3-1.png',
    status: {
      name: "active",
      displayName: "Active"
    },
    faqs: treesFaqs,
    title: 'Tree planting in Kenya',
    description: 'Learn more about Eden Greenspace’s tree-planting projects in Kenya, and how you can help to reduce poverty and restore forests on the ground.',
    gallery: [
      '../../../assets/3-1.png',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/MAIN-PHOTO.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/mashima-site1-26.09.20_31wm.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/pexels-phillip-visser-7064092.jpg',
      'https://eden-blog-images.s3.eu-west-2.amazonaws.com/trees-media/DJI_0152wm.jpg',
      '../../../assets/3-8.png'
    ],
    summary: {
      ecoCoins: 1,
      impact: 'Plant 10 trees',
      bullets: [
        'Over 90% of Kenya has been deforested.',
        'Kenya is a country famous for its diverse wildlife and safaris, but due to human activities such as logging, charcoal burning, and illegal settling to create farmland, ecosystems are under extreme threat!',
        'Only 7% of Kenya is covered with forest.',
        'We work with local communities in the Great Rift Valley (Kijabe Forest) and along the Northern Coast in Lamu County to restore forests on a massive scale, creating jobs, protecting ecosystems, and helping mitigate climate change.',
        'We plant Mangrove trees along the coast with each tree sequestering 150kg of CO2 over the course of their life. ',
        'These Mangrove forests also providing essential stability against soil erosion and improving ocean health by filtering out toxins through their root systems.',
        'We plant Afromontane deciduous trees in the rift valley.',
        'Our efforts are part of the 50 million+ trees which have been produced, planted, and protected in Kenya.',
        'This project directly address the UNEP’s nature and climate crises',
      ],
      images: [
        'sdg1.png',
        'sdg11.png',
        'sdg13.png',
        'sdg15.png',
      ],
      quote: {
        type: 'quote',
        quote: '“Trees and forests play an essential role in mitigating the impact of climate change. Planting trees is one of the most important things we can do to contribute to the health of the planet.”',
        author: '- Antonio Guterres, Secretary General of the UN - 2018',
      }
    },
    content: [
      {
        type: 'subtitle',
        data: 'Planting trees in Kenya',
      },
      {
        type: 'text',
        data: 'It is estimated that only 6% of Kenya is covered with forest now, with over 12 000 hectares destroyed every year.',
      },
      {
        type: 'text',
        data: 'With such a high population, many people depend on the forests for food and work, which results in human activity disrupting and deforesting these ecosystems, which result harm for the Kenyan ecosystem.',
      },
      {
        type: 'image',
        image: '3-2.png',
        caption: 'With such a high population, many people depend on the forests for food and work, which results in human activity disrupting and deforesting these ecosystems, which result harm for the Kenyan ecosystem.',
      },
      {
        type: 'text',
        data: 'Through our partnership with <a target="_blank" href="https://edenprojects.org/>Eden Reforestation Projects</a>, a world-leading reforestation charity, you can help to reduce poverty and restore forests in Kenya.',
      },
      {
        type: 'text',
        data: 'As of December 2020, Eden have planted 485 million trees, and aim to reach 500 million by 2025. Through their life-changing actions, Eden provides many social and environmental benefits such as offering employment in poverty-stricken states, protecting biodiversity, and fighting against climate change.',
      },
      {
        type: 'text',
        data: 'Here is just a little background information on some of the amazing work Eden Reforestation Projects do in Kenya:',
      },
      {
        type: 'google-maps',
      },
      {
        type: 'image',
        image: '3-3.png',
        caption: 'Kenyan tree coverage satellite imagery (green areas indicate tree cover) - note deforestation / tree cover lack to the south of Nairobi, and the Kijabe forest region.',
      },
      {
        type: 'image',
        image: '3-4.png',
        caption: 'Tree canopy loss over last 10 years - note how this deforestation is clustering around the Great Rift Valley, Kijabe forest (north of Nairobi) and the populous areas of Kericho.',
      },
      {
        type: 'image',
        image: '3-5.png',
        caption: 'Extreme deforestation (in red) around the Lamu Coast, which we are actively targeting for reforestation with coastal mangrove trees.',
      },
      {
        type: 'text',
        data: 'Kenya is situated on the eastern coast of Africa, with a population of around 55 million people, making it one of the most inhabited countries in the continent.'
      },
      {
        type: 'text',
        data: '<span class="underline">We plant a wide variety of trees to promote a diverse culture of organisms, to provide a resilient biological stage for fauna to thrive. In the deciduous regions this includes:</span>'
      },
      {
        type: 'list',
        text: [
          'Acacia gerrardii',
          'Acacia kirkii',
          'Acacia xanthophloea',
          'Bridelia micrantha',
          'Calodendrum capense',
          'Cordia africana',
          'Cordia sinensis',
          'Croton machrostachyus',
          'Croton megalocarpus',
          'Dodonaea viscosa',
          'Dombeya torrida',
          'Euclea divinorum',
          'Ficus sur',
          'Ficus sycomorus',
          'Ficus thonningii',
          'Juniperus procera',
          'Maesopsis eminii',
          'Markhamia lutea',
          'Myrsine africana',
          'Nuxia congesta',
          'Olea africana',
          'Podocarpus falcatus',
          'Podocarpus latifolius',
          'Prunus africana',
          'Sesbania sesbans',
          'Trilepisium madagascariense',
        ]
      },
      {
        type: 'text',
        data: '<span class="underline">And the locals love to plant and harvest from these trees:</span>'
      },
      {
        type: 'list',
        text: [
          'Avocado',
          'Croton(Croton megalocarpus)',
          'Juniperus procera',
          'Lemon',
          'Loquat',
          'Moringa(Moringa oleifera)',
          'Papaya',
        ]
      },
      {
        type: 'text',
        data: '<span class="underline">In the coastal mangrove regions we plant the species:</span>'
      },
      {
        type: 'list',
        text: [
          'Avicennia marina',
          'Bruguiera gymnorhiza',
          'Ceriops tagal',
          'Rhizophora mucronata',
          'Sonneratia alba',
        ]
      },
      {
        type: 'text',
        data: 'Our local partners work in very distinct environments, such as indigenous mountain forests in the Great Rift Valley, or mangrove forests and coastal habitats like in Lamu County, and both are in need of help due to deforestation as seen above.'
      },
      {
        type: 'image',
        image: '3-6.png',
        caption: 'Colobus Monkey - characteristic species dependant on the Afromontane tree ecosystems that we are currently reforesting with your help!',
      },
      {
        type: 'text',
        data: 'Kenya is home to an array of beautiful animals, from lions, rhinos, and elephants, to a variety of primates and birds, but almost 500 Kenyan animal species are vulnerable or endangered, primarily due to habitat loss.'
      },
      {
        type: 'text',
        data: 'Deforestation means the destruction of shelter, food sources, and safety from other animals and humans, all of which are contributing to an immense loss of wildlife.'
      },
      {
        type: 'image',
        image: '3-7.png',
        caption: 'Employee of Eden Reforestation Projects (ERP) who manage our local partners on the ground) watering seedlings at the Imenti nursery.',
      },
      {
        type: 'text',
        data: 'In 2009, Eden Reforestation Projects began working with local communities around Kenya in order to try and salvage the remaining forest as well as plant new seedlings for new growth.'
      },
      {
        type: 'text',
        data: 'Since then, over 8.5 million trees have been planted, and 85 000 work days have been created for local people.'
      },
      {
        type: 'text',
        data: 'All of our tree-planting projects have a directly address <span class="bold italic">two</span> of these crises - the nature crisis and the climate crisis.'
      },
      {
        type: 'text',
        data: 'Our trees address the <span class="green bold">nature crisis</span>, in that they increase local biodiversity and are a form of afforestation.'
      },
      {
        type: 'text',
        data: 'They also have a direct positive impact on the <span class="red">climate crisis</span>. This is because the mangrove trees we plant (which make up most of the trees planted) are chosen partly because they sequester a <span class="italic">huge amount of carbon dioxide</span> from the atmosphere. This is because they have deep roots where the carbon is stored.'
      },
      {
        type: 'text',
        data: 'This makes them a highly effective tool at counteracting humanity’s huge carbon output, which is fueling global warming and climate change.'
      },
      {
        type: 'subtitle',
        data: 'By choosing one of our subscription plans, you can plant up to 80 trees per month in Nepal!'
      },
      {
        type: 'text',
        data: 'Help us support these Nepalese  communities, improve Nepals ecosystem, and contribute to saving our planet from climate change.'
      },
      {
        type: 'text',
        data: 'Find out more about the impact of our tree planting, how the trees are protected and how our tree planters are supported in the FAQ’s below!'
      },
    ]
  },
  {
    projectId: 8,
    url: 'coral-reef-protection-in-mexico',
    name: 'Coral Reef Protection in Mexico',
    thumbnail: '8-1.jpg',
    status: {
      name: "active",
      displayName: "Active"
    },
    faqs: [
      {
        dropdown: 'How much does it cost to protect 1 m2 of coral reef habitat?',
        text: 'You can protect 1m2 of coral reef habitat for £0.30 per m2, either by signing up to one of our <a href="/choose-your-plan" class="turq-text bold underline pointer">individual subscription plans</a>, or through a <a href="/business" class="turq-text bold underline pointer">business subscription</a>.<br/><br/>'
          + 'We do not currently sell bracelets for our coral reef projects, but you can purchase our tree-planting bracelets <a class="bold turq-text pointer underline" href="/products/eden-bracelet">here.</a>'
      },
      {
        dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
        text: 'All our environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline turq-text" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
          + 'All of our tree-planting projects have a directly address <span class="bold italic">two</span> of these crises - the nature crisis and the climate crisis.<br/><br/>'
          + 'Our coral reef projects primarily address the nature crisis, as they are focused on restoring coral reef ecosystems and increasing biodiversity in this rich marine habitat.<br/><br/>'
          + 'However, coral reef habitats also address the climate crisis, as they are natural carbon sinks (they store carbon in their skeletons). Similarly, our coral reef project in Hawaii focuses on reducing pollution, as sediment runoff is a major source of coral bleaching.'
      },
      {
        dropdown: 'Why is restoring coral reefs better than just planting more corals?',
        text: 'To give you the best possible justification for why our approach to restoring corals is better than planting new ones, we asked our partners over at the Coral Reef Alliance (CORAL) for their reasoning. This is what they said:<br/><br/>'
          + '<span class="italic">“While many other non-profits take this approach to supporting a healthier future for reefs through planting, we take an alternative approach of conservation by tackling the direct threats to coral reefs: poor water quality, unsustainable fishing practices, ineffective or insufficient governance of marine protected areas, and unsustainable tourism practices.  Our science-based, holistic approach has shown that when you support resilient coastal communities, reduce water and other land-based pollution, and promote sustainable fisheries, reefs can adapt even in the face of global climate change. We are creating the better conditions that coral reefs need to survive.”</span>'
      },
      {
        dropdown: 'What are the carbon benefits of restoring coral reefs?',
        text: 'Coral reef ecosystems are one of the world’s carbon sinks. This means that they <span class="italic">absorb<span> and store more carbon than they emit, by storing it in their skeletons over their lifetimes. Around the world, coral reefs release 1.86 Megatons of carbon per year by means of calcification, but they store between 70 to 90 Megatons of carbon per year by CaCO3 accumulation in their skeleton.  Therefore, carbon net storage by coral reefs is between 68.14 and 88.14 Megatons of carbon per year considering both the released CO2 and the stocked CaCO3 involved in the formation and accumulation of coral skeleton.<br/><br/>'
          + 'Because corals are fantastic at storing carbon, it is essential that we restore coral reefs to full health in order to limit global warming to 1.5 degrees, in addition to rapidly reducing our carbon emissions.'
      },
      {
        dropdown: 'Over how many years does conservation work take place?',
        text: 'After it has been determined that a critical reef location meets a wide range of criteria to become a programme site, CORAL commit to being there and executing their conservation initiatives for a minimum of a decade (10 years+).<br/><br/>'
          + 'They never establish a new site without a clearly determined exit plan that indicates sustainability for a hand-off of the initiatives to the community and participating local organizations, if and when an exit of CORAL involvement occurs.'
      },
      {
        dropdown: 'What are the benefits of saving coral reefs?',
        text: '<span class="bold">Environmental Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Coral reefs restored to healthy state.<br/><br/>'
          + '•&nbsp;&nbsp;Corals more resilient to global warming.<br/><br/>'
          + '•&nbsp;&nbsp;Increased marine biodiversity.<br/><br/>'
          + '•&nbsp;&nbsp;Increased carbon storage in corals.<br/><br/>'
          + '•&nbsp;&nbsp;Well-stocked fisheries.<br/><br/>'
          + '<span class="bold">Social Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Better public awareness of the importance of corals.<br/><br/>'
          + '•&nbsp;&nbsp;Increased fish stocks.<br/><br/>'
          + '•&nbsp;&nbsp;Sustainable fishing practices implemented.<br/><br/>'
          + '•&nbsp;&nbsp;Communities better adapted to climate change.'
      },
    ],
    title: 'Coral Reef Protection in Mexico',
    description: 'Despite being the most biodiverse marine ecosystems in the world on which many other marine life depend, corals are critically threatened. Funding this project will protect the island of Cozumel, Mexico from illegal overfishing and its world-famous corals for years to come.',
    gallery: [
      '../../../assets/8-1.jpg',
      '../../../assets/8-2.jpg',
      '../../../assets/8-3.jpg',
      '../../../assets/8-4.jpg',
      '../../../assets/8-5.jpg',
      '../../../assets/8-6.jpg',
    ],
    summary: {
      quote: {
        type: 'quote',
        quote: '“25% of all marine life depends on coral reefs”',
        author: 'CORAL',
      },
      ecoCoins: 2,
      impact: 'Life saving coral reef',
      images: [
        'sdg11.png',
        'sdg13.png',
        'sdg14.png',
        'sdg15.png',
      ],
      bullets: [
        'Since 1950, 50% of the world’s coral reefs have been lost, with 75% facing critical threat by 2050.',
        'Yet coral reefs are the most biodiverse marine ecosystems in the world.',
        'This project protects corals for the long term by addressing the drivers of coral reef loss.',
        'Key threats addresssed: pollution, overfishing, weak protection measures, unsustainable tourism practices.',
        'Key benefits: preservation of precious reefs, restored and sustainable fisheries, increased carbon sequestration, reduced pollution.',
        'Location and size: 30,000 acres of ocean and coastline around Cozumel, Mexico.',
        'Timeframe: 10+ years action and monitoring.',
        'This project directly address the UNEP’s nature and climate crises.'
      ],
    },
    content: [
      {
        type: 'subtitle',
        data: 'Corals Under Threat'
      },
      {
        type: 'text',
        data: 'Coral reefs are one of the world’s richest sources of life and the most diverse marine ecosystem, despite only covering 0.1% of the earth’s surface. They are essential for supporting both tourism and fishing industries, providing 600 million people with income and food worldwide. As a result, the global value of coral reefs is estimated to be over $375 billion. They are also natural carbon sinks - essential for mitigating the effects of climate change - as they store 70-90 megatonnes of carbon per year by CaCO3 accumulation in their skeletons.'
      },
      {
        type: 'text',
        data: 'Yet our coral reefs are rapidly declining. 50% have been lost since 1950, and the combination of increasing global temperatures and overfishing is projected to place a further 75% under critical threat by 2050. It is imperative to restore degraded corals and protect existing corals to preserve these rich sources of life in our oceans.'
      },
      {
        type: 'image',
        image: '8-7.jpg',
        caption: 'A detailed assessment is made of each coral reef before an action plan is made.',
      },
      {
        type: 'subtitle',
        data: 'The Most Effective Way To Save Corals'
      },
      {
        type: 'text',
        data: 'Several charities simply ‘plant more corals’. But we believe that this is the wrong way to restore coral reef ecosystems. Planted corals are often unsuitable for the native ecosystem and quickly die off, and this approach doesn’t address the <span class="italic">underlying causes of coral reef degradation</span>, such as lack of protection and pollution. Instead, through our partnership with the <a target="_blank" href="https://www.coral.org/">Coral Reef Alliance</a> (CORAL), our coral reef protection projects take a <span class="italic">long-term, holistic</span> approach to coral reef restoration to restore and protect precious coral reefs.'
      },
      {
        type: 'text',
        data: 'Each project:<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Identifies threats to coral reefs.</span> This could be poor water quality, unsustainable fishing practices, ineffective or insufficient governance of marine protected areas or unsustainable tourism practices.<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Creates a plan.</span> CORAL then uses a science-backed approach to create a long-term plan to address all these problems simultaneously, over at least 10 years.<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Takes action to save the reefs.</span> This could include supporting resilient coastal communities, reducing water and other land-based pollution, coastal habitat restoration and promoting sustainable fisheries. monitor the results over several years.<br/><br/>'
          + '•&nbsp;&nbsp;<span class="bold">Ensures long-term management.</span> Each site is monitored for several years, and local partners are put in place to ensure lasting protection.<br/><br/>'
      },
      {
        type: 'google-maps',
      },
      {
        type: 'image',
        image: '8-8.jpg',
        caption: 'The wharf in Cozumel, Mexico.',
      },
      {
        type: 'subtitle',
        data: 'Coral Reef Protection In Cozumel, Mexico'
      },
      {
        type: 'text',
        data: 'Coral reefs in Cozumel, Mexico are home to 500 fish species, 60 species of coral and other marine life, including turtles, rays and eels, across 30,000 acres of ocean and coastline. But these reefs are under threat from overfishing, environmental changes and unsustainable tourism practices, including 4.2 million annual visitors from cruise ships alone. This project protects the coral ecosystems around Cozumel by implementing improved protection measures and increasing its protective status. '
      },
      {
        type: 'text',
        data: 'Although the Cozumel Reefs National Park is a marine protected area (MPA), and therefore subject to fishing regulations, there are insufficient resources for patrolling this area, resulting in illegal fishing activities on the reef near the cruise port. To counter this issue, this project invests in Cozumel’s patrol program operations so that fish populations can rebound and be protected for the future, an essential element to healthy reefs and sustainable fishing practices.'
      },
      {
        type: 'text',
        data: 'This project also contributes to a larger goal of establishing the area as a Particularly Sensitive Sea Area(PSSA) by the end of 2025. This would ensure that the reefs receive  special protection through action by the International Maritime Organization(IMO) as being ecologically, socio- economically or scientifically valuable, and vulnerable to damage by international shipping activities.This improved protection status will ultimately enhance the visibility of the area as a significant marine ecosystem and promote its conservation on a regional and international level for the long term.'
      },
      {
        type: 'text',
        data: 'Interested in supporting this project? You can protect the Cozumel coral reef for £0.30 per m2, either by signing up to our <a href="/choose-your-plan" class="turq-text bold underline pointer">individual subscription plan</a>, or with a <a href="/business" class="turq-text bold underline pointer">business subscription</a>.'
      },
      {
        type: 'image',
        image: '8-9.png',
        caption: '',
      },
    ],
  },
  {
    projectId: 10,
    url: 'landscape-restoration-in-kenya',
    name: 'Landscape Restoration in Kenya',
    thumbnail: '10-1.png',
    status: {
      name: "in-development",
      displayName: "In Development"
    },
    title: 'Landscape Restoration in Kenya',
    description: 'As the latest IPCC report estimates that world is on a trajectory towards 3.2°C warming above pre-industrial levels, we need to do everything we can to mitigate and adapt to global warming. Restoring our natural carbon sinks is one of the most important steps we can take to do this, as these sinks sequester huge amounts of carbon, and can have a significant cooling effect on the world’s climate.',
    gallery: [
      '../../../assets/10-1.png',
    ],
    summary: {
      quote: {
        type: 'quote',
        quote: '“Maintaining the resilience of biodiversity and ecosystem services at a global scale depends on effective and equitable conservation of approximately 30–50% of Earth’s land, freshwater and ocean areas, including currently near-natural ecosystems (high confidence).”',
        author: 'IPCC Longer Report p.106',
      },
      ecoCoins: 2,
      impact: 'Life saving coral reef',
      images: [
        'sdg1.png',
        'sdg13.png',
        'sdg14.png',
        'sdg15.png',
      ],
      bullets: [
        'Since 2001, over 100,000 hectares of canopy cover has been lost in Lamu.',
        'Mangrove restoration is a precious carbon sink, urgently in need of protection as part of the fight against global warming.',
        'Landscape restoration is a new, holistic approach to restoring nature.',
        'Key issues addressed: deforestation, biodiversity loss, unsustainable farming methods.',
        'Key environmental benefits: increased carbon sequestration, protecting endangered species, increased canopy cover and restored fisheries.',
        'Size: 154,000+ hectares, include coastal mangrove forests and inland forest habitats.',
        'Timeframe: 15-20 years restoration and monitoring, to create long-lasting change.',
        'This project directly address the UNEP’s nature and climate crises.'
      ],
    },
    content: [
      {
        type: 'subtitle',
        data: 'Why is Landscape Restoration Important?'
      },
      {
        type: 'text',
        data: 'As the latest IPCC report estimates that world is on a trajectory towards 3.2°C warming above pre-industrial levels, we need to do everything we can to mitigate and adapt to global warming. Restoring our natural carbon sinks is one of the most important steps we can take to do this, as these sinks sequester huge amounts of carbon, and can have a significant cooling effect on the world’s climate.'
      },
      {
        type: 'text',
        data: 'The 150,000 ha restoration site in Lamu, in Northern Kenya, is an incredibly precious carbon sink, as it is largely a mangrove ecosystem. Mangroves sequester 3-5 more carbon than other forest types (Donato et al., 2011), as their deep root systems allow them to store huge amounts of carbon below ground. Similarly, mangrove forests support natural fisheries, increasing biodiversity, and help coastal communities to adapt to a warming world by reducing the impact of storm surges and erosion (Spalding et al., 2014). Lamu has a long history of trade across the Indian Ocean, built around the use of mangroves in making dhows (traditional sailboats), carvings and tannins for dyes. 70% of fishing communities still derive their cincome from these natural fisheries, but the Kenya Forest Service has classified over 14,000 ha of Lamu’s mangroves are degraded.'
      },
      {
        type: 'quote',
        quote: '“Landscape Restoration is more than just planting trees – it is restoring a whole landscape to meet present and future needs and to offer multiple benefits and land uses over time.”',
        author: '- Eden Projects'
      },
      {
        type: 'text',
        data: 'Inland, Lamu has lost 112,000 ha of canopy cover since 2001, driven by unsustainable agriculture and charcoal production. To address this, this landscape restoration project will deliver sustainable agriculture practices for the local community, and identify and address the drivers of deforestation. This will enable the rich wildlife corridors in Lamu’s inland forests to flourish, which in turn help to capture freshwater to recharge groundwater streams, which feed into the mangrove forests nearer the coast.'
      },
      {
        type: 'text',
        data: 'It is this long-term, holistic approach which makes landscape restoration effective at addressing complex and interconnected environmental issues, like deforestation and coastal erosion.'
      },
      {
        type: 'image',
        image: '10-2.jpg',
        caption: '',
      },
      {
        type: 'subtitle',
        data: 'How Landscape Restoration Works.'
      },
      {
        type: 'text',
        data: '1. Identify drivers of deforestation and biodiversity loss.<br/><br/>'
          + '2. Design a comprehensive restoration plan to address each driver.<br/><br/>'
          + '3. Take action: planting trees, protecting existing forest, restoring fisheries and incorporating sustainable agroforestry.<br/><br/>'
          + '4. Measure and monitor for 15-20 years, to ensure long-lasting change.<br/><br/>'
          + 'Actions taken to address the drivers of deforestation and restore the Lamu landscape include:<br/><br/>'
          + '•&nbsp;&nbsp;Planting new mangrove trees in deforested areas, and around the coast to support resilience to sea level rise.<br/><br/>'
          + '•&nbsp;&nbsp;Restoring 10,000+ ha of inland coastal forest habitats by planting native trees.<br/><br/>'
          + '•&nbsp;&nbsp;Protecting a further 49,000+ ha of inland habitats, e.g. by working to reduce fire and charcoal production in the area.<br/><br/>'
          + '•&nbsp;&nbsp;Incorporating sustainable farming practices to improve food security and soil health inland, such as agroforestry.<br/><br/>'
          + '•&nbsp;&nbsp;Employing local people to restore and protect the land.<br/><br/>'
          + '•&nbsp;&nbsp;Scaling up measuring and monitoring, including measuring canopy cover, height of trees, biomass, soil carbon, biodiversity indicators, threatened or endangered species, social benefits and carbon reductions, removals and emissions. <br/><br/>'
          
      },
      {
        type: 'text',
        data: 'Because of the scale of this project, funding of this project starts at £5000 per year. We will submit detailed annual reports detailing the progress on all the above metrics, including trees planted and carbon stored. '
      },
      {
        type: 'text',
        data: 'Interested in supporting this project? Please get in touch via contact@edengreenspace.co.uk.'
      },
    ],
  },
  {
    projectId: 11,
    url: 'peatland-restoration-in-scotland',
    name: 'Peatland Restoration in Scotland',
    thumbnail: '11-1.png',
    status: {
      name: "active",
      displayName: "Active"
    },
    title: 'Peatland Restoration Project in Scotland',
    description: 'Peatlands are our most precious terrestrial carbon sink, absorbing 30% of terrestrial carbon despite only covering 3% of the earth’s surface. Yet peatland is being degraded across the globe, and UK peatland has been diminished by 94% over the last 100 years. Our peatland restoration project will restore over 260 ha of peatland habitat by rewetting raised bogs in the Scottish lowlands. This allows the peat formation process to resume and provides habitat for endangered invertebrates and birds. Most importantly, this project reduces carbon emissions by turning peatland back into a carbon sink, actively addressing both the climate and nature crises in the UK.',
    gallery: [
      '../../../assets/11-1.png',
    ],
    summary: {
      quote: {
        type: 'quote',
        quote: '“Peatlands cover 3% of the world’s land area, yet hold nearly 30% of the soil carbon”',
        author: 'International Union for the Conservation of Nature (IUCN)',
      },
      ecoCoins: 2,
      impact: 'Life saving coral reef',
      images: [
        'sdg11.png',
        'sdg13.png',
        'sdg15.png',
      ],
      bullets: [
        'Peatlands are our most precious terrestrial carbon sink, absorbing double the amount of CO2 than the world’s forests.',
        'Yet 12% of global peatlands have been degraded, contributing to 4% of greenhouse gas emissions - the same as the aviation industry. In the UK, peatland area has diminished by 94% over the last 100 years.',
        'This project restores UK peatland by rewetting raised bogs in the Scottish lowlands. ',
        'This allows the peat formation process to resume and provides habitat for endangered invertebrates and birds.',
        'Location and size: this project will ultimately span 260 ha across the Scottish lowlands.',
        'Carbon Sequestration = 0.1kg CO2 per m2 restored.',
        'Timeframe: 4+ years action and monitoring.',
        'This project directly address the UNEP’s nature and climate crises.'
      ],
    },
    content: [
      {
        type: 'subtitle',
        data: 'Why Peatland Matters'
      },
      {
        type: 'text',
        data: 'Peatland - a general term covering moors, bogs, marshes, mires or fens - is a type of wetland comprised of dead plant matter, prevented from biodegrading as it is saturated in water. Constantly growing over thousands of years, when left undisturbed, this layer of organic matter (peat) slowly sequesters and permanently stores carbon in the soil.'
      },
      {
        type: 'text',
        data: 'In short, peatlands are one of the most carbon-rich ecosystems on earth, by some counts storing more than twice as much carbon as all the world’s forests combined. This makes peatland the biggest terrestrial carbon sink, despite only covering 3% of the world’s surface (Source: IUCN).'
      },
      {
        type: 'text',
        data: 'As well as benefiting the climate, healthy peatlands also reduce flood risks, by slowing the flow of water from upland areas and providing floodplain storage in the lowlands. They also provide a haven for biodiversity, including wading birds, rare insects and plants. '
      },
      {
        type: 'image',
        image: '11-2.png',
        caption: 'Rare and endangered essential invertebrates include the Large Marsh Grasshopper (left) and the Large Heath Butterfly (right).',
      },
      {
        type: 'subtitle',
        data: 'The Problem'
      },
      {
        type: 'text',
        data: 'Across the globe, peatland has been substantially degraded. The Global Peatlands Initiative reported that worldwide, “12% of current peatlands have been drained and degraded, contributing to 4% of annual global human-induced emissions.” In the UK over the last 100 years, the area of undisturbed peatland has diminished by around 94%, from c.95,000 ha to only c.6,000 ha. The result is the widespread degradation of 9000 years worth of peatland growth, in a matter of decades.'
      },
      {
        type: 'text',
        data: 'This dramatic decline has been driven by multiple factors, chiefly agriculture, drainage and commercial peat extraction. Agricultural sprawl has seen peatland area converted into agricultural land, pollution caused by fertiliser and pesticides run-off, and degradation from livestock. The peatland is also gradually drying out as a result of intrusive drainage, afforestation and increasing water use, which lowers the groundwater table.'
      },
      {
        type: 'text',
        data: 'The result is huge CO2 emissions and biodiversity loss. When peatland dries out, instead of sequestering carbon in its water-saturated bogs, it instead releases that carbon as the organic matter rots. The UK Centre for Ecology & Hydrology estimates that 20 million tonnes of CO2 are emitted by peatland degradation in the UK every year (for context, that’s 5% of the UK’s annual emissions). Additionally, peatland degradation has rendered many essential invertebrate species critically endangered, and has damaged the soil health in surrounding areas.'
      },
      {
        type: 'image',
        image: '11-3.png',
        caption: 'By allowing water to saturate its bogs, peatland can be turned back into a carbon sink, not a carbon source. ',
      },
      {
        type: 'subtitle',
        data: 'The Mission to Save UK Peatland'
      },
      {
        type: 'text',
        data: 'Through our partnership with leading invertebrate protection charity, Buglife, this project directly funds the restoration of peatland in the Scottish Lowlands. Following the successful restoration of over 230 hectares of peatland known as the Slamannan Bog (2014-2018), this project will restore the Falkirk Lowland Raised Bog area across the Slamannan Plateau in Falkirk. '
      },
      {
        type: 'text',
        data: 'Focusing on nine degraded sites over several years, this project will ultimately restore peat-forming activity to over 260 hectares of peatland across the area. This project especially targets ‘raised bogs’, a particular type of peatland which stores massive amounts of carbon in Scotland (as much as 1,620 megatonnes across the country), providing both a carbon sink and flood management when in a good condition. '
      },
      {
        type: 'text',
        data: 'The project work includes::<br/><br/>'
          + '•&nbsp;&nbsp; Reprofiling drainage ditches to ensure water is retained on the bog.<br/><br/>'
          + '•&nbsp;&nbsp; Removing scrub and trees from the bog.<br/><br/>'
          + '•&nbsp;&nbsp; Adding in dams to drainage ditches to slow water flow and create bog ponds (ideal for aquatic bog dwellers).<br/><br/>'
          + '•&nbsp;&nbsp; Creating conservation management schemes across the nine sites.<br/><br/>'
          + '•&nbsp;&nbsp; Assessing and monitoring each site in the long-term so ensure the effectiveness of each intervention and inform future restoration work.<br/><br/>'
      },
      {
        type: 'text',
        data: 'These techniques will collectively restore water levels across the bogs and rewet the surface, allowing the peat formation process to resume and restoring the habitat for rare bog-specialist species and other peatland wildlife. Most importantly, this will reduce carbon emissions and turns the peatland back into a carbon sink, sequestering 0.1kg CO2 per m2, actively addressing both the climate and nature crises in the UK.'
      },
      {
        type: 'text',
        data: 'Interested in supporting this project? You can fund this restoration work either by signing up to our <a href="/choose-your-plan" class="turq-text bold underline pointer">individual subscription plans</a>, or by becoming a <a href="/business" class="turq-text bold underline pointer">business partnetr</a>.'
      },
      {
        type: 'image',
        image: '11-4.jpg',
        caption: ''
      },
    ],
    faqs: [
      {
        dropdown: 'Which <span class="bold green">planetary crisis</span> does this project address?',
        text: 'All Eden’s environmental projects have to pass a strict range of tests to make sure that they are effective, quantifiable and have a direct impact on one of the UNEP’s <a class="bold underline black" href="/overview" target="_blank">triple planetary crisis</a>.<br/><br/>'
          + 'This peatland restoration project primarily addresses the <span class="red bold">climate crisis</span>, as peatland habitat is a precious carbon sink, with UK peatlands alone storing an estimated 3.2 billion tonnes of carbon, according to the UK Centre for Ecology and Hydrology.<br/><br/>'
          + 'However, peatland restoration also addresses the <span class="green bold">nature crisis</span>, as it increases biodiversity, protects endangered invertebrate species and improves soil health.'
      },
      {
        dropdown: 'How much does it cost to restore 1 m2 of peatland habitat?',
        text: 'This project works over several years on a large scale. We break down the cost so that anyone can support this peatland restoration work for £1.50 per m2, either by signing up to one of our <a href="/choose-your-plan" class="turq-text bold underline pointer">individual subscription plans</a>, or by becoming a <a href="/business" class="turq-text bold underline pointer">business partnetr</a>.<br/><br/>'
          + 'We do not currently sell bracelets for our peatland restoration project, but you can purchase our tree-planting bracelets <a class="bold turq-text pointer underline" href="/products/eden-bracelet">here.</a>'
      },
      {
        dropdown: 'Why is planting trees bad for peat bogs?',
        text: 'Although it may seem counterintuitive, trees and associated furrows dry out neighbouring peat bogs, causing the plant matter to rot and <span class="italic">release<span>, rather than <span class="italic">sequester<span>, carbon.<br/><br/>'
          + 'Because healthy peatland stores far more carbon than trees, it is crucial to prioritise these habitats over tree planting.'
      },
      {
        dropdown: 'How much carbon is stored through peatland restoration?',
        text: 'Peatland degradation accounts for 20 million tonnes of CO2 emissions every year, according to the UK Centre for Ecology and Hydrology.<br/><br/>'
          + 'By funding the restoration of peatland habitat, this project estimates to return 0.1 kg carbon to the soil per m2 restored, that’s 1000kg per hectare restored.'
      },
      {
        dropdown: 'Which invertebrate species are at risk from peatland degradation?',
        text: '<span class="bold">Rare and Endangered Essential Invertebrates include:</span><br/><br/>'
          + '•&nbsp;&nbsp;Blue Ground Beetle (<span class="italic">Carabus intricatus<span>) <br/><br/>'
          + '•&nbsp;&nbsp;10 Spotted Pot Beetle (<span class="italic">Cryptocephalus decemmaculatus<span>) <br/><br/>'
          + '•&nbsp;&nbsp;Large Marsh Grasshopper (<span class="italic">Stethophyma grossum<span>) <br/><br/>'
          + '•&nbsp;&nbsp;Black Bog Ant (<span class="italic">Formica candida<span>) <br/><br/>'
          + '•&nbsp;&nbsp;The Large Heath Butterfly (<span class="italic">Coenonympha tullia<span>) <br/><br/>'
          + '•&nbsp;&nbsp;The Bog Bush Cricket (<span class="italic">Metrioptera brachyptera<span>) <br/><br/>'
          + '•&nbsp;&nbsp;Mire Pill Beetle (<span class="italic">Curimopsis nigrita<span>) <br/><br/>'
          + '•&nbsp;&nbsp;Black Ground beetle (<span class="italic">Pterostichus aterrimus<span>)'
      },
      {
        dropdown: 'What are the environmental and social benefits of restoring peatland habitat?',
        text: '<span class="bold">Environmental Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Peat bogs restored to healthy state, allowing peat formation process to resume.<br/><br/>'
          + '•&nbsp;&nbsp;Reduces UK carbon emissions.<br/><br/>'
          + '•&nbsp;&nbsp;Reduced risks to endangered species.<br/><br/>'
          + '•&nbsp;&nbsp;Increased local biodiversity.<br/><br/>'
          + '•&nbsp;&nbsp;Improved soil health.<br/><br/>'
          + '<span class="bold">Social Impact:</span><br/><br/>'
          + '•&nbsp;&nbsp;Reduced risk of flooding.<br/><br/>'
          + '•&nbsp;&nbsp;Improved soil health for local agriculture.<br/><br/>'
          + '•&nbsp;&nbsp;Helps the UK transition towards net zero.'
      },
    ],
  }
];

export const getProjectUrl = (id: Project['id']): string => {
  return BIOS.find((bio) => id === bio.projectId)?.url;
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  public bios = BIOS;

  @Input() account = false;
  @Input() projects: SelectedProject[];

  private seoService: SeoService;

  constructor(seoService: SeoService) { 
    this.seoService = seoService;
  }

  ngOnInit(): void {
    if(!this.account) {
      this.seoService.setSeo('Our Projects', 'Our world leading environmental projects provide simple ways to support and track environmental impact across the world, from tree planting to ocean plastic removal.');
    }
   
    if(this.projects) {
      this.bios = this.bios.filter(
        (bio) => this.projects.find(
          (project) => bio.projectId === project.id
        )
      ).map((bio) => {
        const projectWithTokens = this.projects.find(
          (project) => bio.projectId === project.id
        )

        return {
          ...bio,
          tokens: projectWithTokens.tokens
        };
      });
    }
  }

  public getLogo(id: number): string | null{
    return projects.find((project) => project.id === id)?.image;
  }

}
