<div class="content">
    <h1>What Our Clients Say</h1>
    <br /><br />
    <app-carousel class="desk car" [contentMode]="true" [itemsPerRow]="3">
        <ng-template [ngTemplateOutlet]="reviews"></ng-template>
    </app-carousel>
    <app-carousel class="mob" [contentMode]="true" [itemsPerRow]="1">
        <ng-template [ngTemplateOutlet]="reviews"></ng-template>
    </app-carousel>
    <ng-template #reviews>
        <div class="review middle-review">
            <img class="review-image" src="../../../assets/simon.jpg" />
            <div class="review-name">Simon</div>
            <pre class="review-quote">
                "We really wanted to do something which reflected our desire to give something back to the environment but it needed to be simple to use and not too expensive! Eden Greenspace is a system which fulfils that need beautifully for us by allowing our vendors to support their green projects for every sale. They give us a different USP when out on MAs and helps to differentiate us without having to reduce fees!"
            </pre>
            <div class="review-type italic">Winkworth Devizes Estate Agents</div>
            <img class="logo" src="../../../assets/winkworth-logo.png" />
        </div>
        <div class="review middle-review">
            <img class="review-image" src="../../../assets/jane.png" />
            <div class="review-name">Jane</div>
            <pre class="review-quote">
                "For us the partnership with Eden Greenspace helped us identify key local projects that support the significant environmental crises we face, but with national coverage representing our geographic spread across the UK.  Our employees really engaged with the project voting system, the resources provided by Eden supported the rollout within Cavendish Nuclear. We are really pleased with the positive outcomes in terms of marine plastic waste recovered, wildflower meadows seeded and peatland habitat restored!"
            </pre>
            <div class="review-type italic">Cavendish Nuclear</div>
            <img class="logo" src="../../../assets/cavendish-logo.png" />
        </div>
        <div class="review middle-review">
            <img class="review-image" src="../../../assets/jeff.png" />
            <div class="review-name">Jeff</div>
            <pre class="review-quote">
                "We recognise that sustainability is not a badge to ‘earn’ but a dynamic science that requires study and tailormade implementation. We love the way Eden Greenspace offers such a diverse range of worldwide causes, giving us the flexibility to contribute so widely, and to demonstrate to our clients how we can contribute to so many other environmental issues and social opportunities."
            </pre>
            <div class="review-type italic">Ochre Interior Designers</div>
            <img class="logo" src="../../../assets/ochre-logo.png" />
        </div>
        <div class="review middle-review">
            <img class="review-image" src="../../../assets/dan.jpeg" />
            <div class="review-name">Dan</div>
            <pre class="review-quote">
                "A company with Big Goals!🔥 We ended up planting thousands of trees in Kenya and Madagascar. We couldn't be happier with our new partnership and are excited to grow alongside Eden while making a positive impact."
            </pre>
            <div class="review-type italic">Garden Landscaping</div>
        </div>
        <div class="review">
            <img class="review-image" src="../../../assets/emma.png" />
            <div class="review-name">Emma</div>
            <pre class="review-quote">
"Eden Greenspace gives my mosaic business a fantastic opportunity to support the environment. They provide badges to attach to each mosaic artwork so that each time a client buys a mosaic, 10 trees are planted on their behalf! Sales have definitely increased as a result of my connection with Eden Greenspace."
            </pre>
            <div class="review-type italic">Wiltshire artist</div>
        </div>
    </ng-template>
</div>
<div class="content">
    <img class="transparency-image" src="../../../assets/transparency-background.png" />
    <div class="transparency-text-container">
        <pre class="transparency-text">
            <div class="trans-text-inner">
<img class="pie-chart clear-image-text" src="../../../assets/pie-chart.png" />
<div class="text-block">What you see is what you get. As a not-for-profit, we guarantee that <span class="bold">100% of the money donated</span> to our environmental projects goes directly into funding restoration work on the ground.</div>
<div class="text-block">How do we fund our curation work? We charge our corporate clients an additional 17.5% fee on all donations, to access our online account, marketing suite and receive expert advice.</div>
<div class="text-block">All donations received through Eden’s platform can be viewed on our <span class="pointer bold clickable underline orange" routerLink="/about/impact">Public Impact Registry</span>.</div>
</div><button class="fom-button" routerLink="/about/overview">Find Out More</button>
        </pre>
    </div>
</div>
