<div [class]="faqInput ? 'content white' : 'content'">
    <app-title text="FAQs" large="true"></app-title>
    <div *ngIf="!faqInput" class="dropdown-container">
        <app-dropdown 
            class="dropdowns"
            dropdown="Where does your money go?"
            [text]="faqTexts[0]"
        ></app-dropdown>
        <app-dropdown 
            class="dropdowns"
            dropdown="What are your values?"
            [text]="'1. To fight climate change, by carbon sequestration (via tree planting), rainforest protection and green energy. \n\n2. To restore the environment, by removing plastic from the ocean, rewilding brown sites, and working on wildlife conservation. \n\n3. To make a collective impact, by encouraging ordinary people to join a movement which takes real action, supporting world-leading solutions to the climate and ecological crises our Earth faces.'"
        ></app-dropdown>
        <app-dropdown 
            class="dropdowns"
            dropdown="Are we a charity?"
            [text]="'It might seem at odds with our aim to fight climate change and environmental degradation. But being a charity comes with significant financial limitations which hold us back from expanding our community and scaling up our collective impact.
    
Ultimately, we can’t rely on donations and government grants to reach the impact we want to reach. We rely on support from investors to fulfil our goals at the scale our earth needs.

We have endeavoured to operate like a charity in all but name. We borrow the best elements from charitable organizations to serve our cause, such pledging than a minimum of 80% of your money goes directly into supporting our projects.

Similarly, transparency is built into everything we do. Unlike other organisations, we publish all the details of each project, and tell you exactly where your money is going. We are transparent about our methods for calculating your impact and so we are able to detail the precise impact of every penny.

But sometimes the numbers speak louder than words, so we publish the total impact our community has had for each project, and our total impact to date. Above all, it is our top priority to ensure that your money is used efficiently, and has the biggest possible impact in protecting our earth.'"
        ></app-dropdown>
        <app-dropdown 
            class="dropdowns"
            dropdown="Why did you start Eden Greenspace?"
            [text]="faqTexts[1]"
        ></app-dropdown>
        <app-dropdown 
            class="dropdowns"
            dropdown="How can you prove my impact?"
            [text]="faqTexts[2]"
        ></app-dropdown>
        <app-dropdown 
            class="dropdowns"
            dropdown="Is my carbon footprint really that big?"
            [text]="faqTexts[3]"
        ></app-dropdown>
    </div>
    <div *ngIf="faqInput" class="dropdown-container">
        <app-dropdown *ngFor="let faq of faqInput"
            class="dropdowns"
            [dropdown]="faq.dropdown"
            [text]="faq.text"
            [smallText]="true"
        ></app-dropdown>
    </div>
</div>

