import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent {
  @Input() color: string;

  public partnerLogos: string[] = [
    'winkworth-logo.png',
    'ochre-logo.png',
    'cavendish-logo.png',
    'durham.png',
    'ucl.png',
  ];
}
